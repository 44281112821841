import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import ThirdColumnContainer from "./styles";
import ChatHeader from "./chatHeader/ChatHeader";
import ChatInputComponent from "./chatInputComponent/ChatInputComponent";
import Chat from "./chat/Chat";
import useGetData from "../../../hooks/useGetData";
import axios from "axios";
import ReactTostify from "../../../common/ReactTostify";
import { InstanceWithAuth } from "../../../App";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import Commets from "./commets/Commets";
import ColumnHerder from "./columnHerder/ColumnHerder";

const SecondColumn = ({ selectedEmailId, ticketID, ticket, refetchDataTicket }) => {
  const {
    data: email,
    error,
    loading,
    refetchData,
  } = useGetData(`support/tickets/${ticketID}`);

  const { data: notes, refetchData: refetchNotes } = useGetData(
    `support/tickets/notes/${ticketID}`
  );

  const [currentEmployeeImage, setCurrentEmployeeImage] = useState("");
  const [selectedTab, setSelectedTab] = useState("Email");
  const { auth } = useSelector((state) => state);
  const [savingComment, setSavingComment] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);
  const [editingNote, setEditingNote] = useState(null);

  const handleSend = async (data) => {
    setSendingMail(true);
    const { to, cc, bcc, message, files } = data;
    const formData = new FormData();
    // "sender",
    // `${currentEmployeeImage?.first_name} ${currentEmployeeImage?.last_name}`
    formData.append("sender", `Starthub`);
    formData.append("message", message);

    // to.forEach((recipient, index) =>
    //   formData.append(`to[${index}]`, recipient)
    // );
    // cc.forEach((recipient, index) =>
    //   formData.append(`cc[${index}]`, recipient)
    // );
    // bcc.forEach((recipient, index) =>
    //   formData.append(`bcc[${index}]`, recipient)
    // );

    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `support/tickets/threads/${ticketID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        // ReactTostify("success", response?.data?.message);
        refetchData();
      }
    } catch (error) {
    } finally {
      setSendingMail(false);
    }
  };

  const handleCommentSend = async (data) => {
    setSavingComment(true);
    const { comment, files } = data;
    const formData = new FormData();
    formData.append(
      "from_name",
      `${currentEmployeeImage?.first_name} ${currentEmployeeImage?.last_name}`
    );
    formData.append("notes", comment);

    // if (editingNote) {
    //   formData.append(`attachments[]`, "");
    // } else {
    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });
    // }

    try {
      let response;
      if (editingNote) {
        response = await InstanceWithAuth.post(
          `support/tickets/notes/${ticketID}/update`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      } else {
        response = await InstanceWithAuth.post(
          `support/tickets/notes/${ticketID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      if (response.status === 201 || response.status === 200) {
        ReactTostify("success", response?.data?.message);
        refetchNotes();
      }
    } catch (error) {
    } finally {
      setSavingComment(false);
      setEditingNote(null);
    }
  };

  const handleEditNote = (note) => {
    setEditingNote(note);
  };

  return (
    <ThirdColumnContainer>
      <Skeleton
        loading={loading}
        active
        style={{ margin: "10px", width: "auto" }}
      >
        {ticket !== null ? (
          <>
            {/* <ColumnHerder
              showDrawerContactDrawer={showDrawerContactDrawer}
              selectedEmailId={selectedEmailId}
              email={email}
              selectedEmails={selectedEmails}
            /> */}
            <ChatHeader
              selectedEmailId={selectedEmailId}
              setCurrentEmployeeImage={setCurrentEmployeeImage}
              currentEmployeeImage={currentEmployeeImage}
              ticket={ticket}
              ticketID={ticketID}
              refetchData={refetchData}
              refetchDataTicket={refetchDataTicket}
            />
            {selectedTab === "Email" ? (
              <Chat
                email={email}
                ticket={ticket}
                currentEmployeeImage={currentEmployeeImage}
              />
            ) : (
              <Commets
                selectedEmailId={selectedEmailId}
                refetchNotes={refetchNotes}
                notes={notes}
                onEdit={handleEditNote}
              />
            )}
            <ChatInputComponent
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onSend={handleSend}
              handleCommentSend={handleCommentSend}
              savingComment={savingComment}
              email={email}
              sendingMail={sendingMail}
              notes={notes}
              editingNote={editingNote}
            />
          </>
        ) : null}
      </Skeleton>
    </ThirdColumnContainer>
  );
};

export default SecondColumn;

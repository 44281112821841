import React, { useState, useEffect } from "react";
import "./styles";
import { Table, PaginationContainer } from "./styles";
import { useSelector } from "react-redux";
import { Switch, Skeleton, Pagination, Popconfirm } from "antd";
import { FaPlus } from "react-icons/fa6";
import ReactTostify from "../../../common/ReactTostify";
import { useNavigate } from "react-router-dom";
import { InstanceWithAuth } from "../../../App";
import useGetData from "../../../hooks/useGetData";
import Spinner from "../../../common/Spinner";
import CustomTooltip from "../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import { ReactComponent as TrashIcon } from "./icons/trash.svg";
import { ReactComponent as MoveDown } from "./icons/move-down.svg";
import { ReactComponent as Call } from "./icons/phone4.svg";
import { ReactComponent as Play } from "./icons/triangle-right.svg";
import { ReactComponent as Printer } from "./icons/printer.svg";
import FormatDate from "../../../common/FormatDate";
import DataNotFound from "../../../common/DataNotFound";
import SearchFilters from "../SearchFilters/SearchFilters";
const VoiceMailsTable = ({
  open,
  setEndtDate,
  setStartDate,
  setEventType,
  setSearchParam,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window?.innerWidth < 1400);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const { app } = useSelector((state) => state);
  const [eventName, setEventName] = useState("");
  const [searchparma, setSearchparma] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const callsData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window?.innerWidth < 1400);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allUserIds = callsData?.map((user) => user);
      setSelectedRows(allUserIds);
    } else {
      setSelectedRows([]);
    }
  };
  const handleChecked = (userId) => {
    if (selectedRows.includes(userId)) {
      // Remove userId from selectedRows if already selected
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    } else {
      // Add userId to selectedRows if not selected
      setSelectedRows([...selectedRows, userId]);
    }
  };

  const TruncateText = ({ text }) => {
    const maxLength = 10;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <div style={{ paddingBottom: "35px" }}>
      <SearchFilters
        setEventName={setEventName}
        setSearchparma={setSearchparma}
        setStartDate={setStartDate}
        setEndtDate={setEndtDate}
        setEventType={setEventType}
        setSearchParam={setSearchParam}
        // handleSearchInput={hanlefetchMailBoxData}
      ></SearchFilters>
      <Table open={open} sidebarCollapsed={app?.sidebarCollapsed}>
        <tr className="heading-row">
          <th className="checkbox">
            <div className="custom-input">
              <input
                type="checkbox"
                checked={selectAll}
                id="checkAll"
                className={`input-checkbox ${selectAll ? "checked" : ""}`}
                onChange={toggleSelectAll}
              />
              <label for="checkAll"></label>
            </div>
          </th>
          <th className="name title">NAME</th>
          <th className="caller-name title">CALLER ID</th>
          <th className="phone title">PHONE</th>
          <th className="date title">DATE</th>
          <th className="time title">TIME</th>
          <th className="reason title"></th>
          <th className="status title">STATUS</th>
          <th className="actions title">ACTIONS</th>
        </tr>
        {loading ? (
          <Skeleton active></Skeleton>
        ) : !callsData || callsData?.length == 0 ? (
          <DataNotFound label={"No Results Found!"}></DataNotFound>
        ) : (
          callsData &&
          callsData?.map((call, index) => (
            <tr
              key={index}
              // className="td-heading"
              className={
                hoveredRow === index ? "hovered td-heading" : "td-heading"
              }
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <td className="td-checkbox td-title">
                <div className="custom-input">
                  <input
                    type="checkbox"
                    // disabled
                    // className="input-checkbox"
                    // checked={selectAll || selectedRows.includes(idx)}

                    checked={selectedRows?.includes(index)}
                    id={`check${index}`}
                    onChange={() => handleChecked(index)}
                  />
                  <label for={`check${index}`}></label>
                </div>
              </td>
              <td className="td-id td-title">Theresa Webb</td>
              <td className="td-mailbox-id td-title">Esther Howard</td>
              <td className="td-sender td-title">(205) 555-0100</td>
              <td className="td-send-date td-title">8 sep, 2020</td>
              <td className="td-type td-title">06:32 pm</td>
              <td className="td-QTY td-title"></td>
              <td className="td-status td-title">
                <span>Incoming Call</span>
              </td>

              <td className="td-action td-title">
                <div className="icons-wrapper">
                  <CustomTooltip text={"Forward Mail"} marginTop="-25px">
                    <span
                    // onClick={() =>
                    // }
                    >
                      <Play />
                    </span>
                  </CustomTooltip>
                  <CustomTooltip text={"Picked Up"} marginTop="-25px">
                    <Popconfirm
                      title="Are you sure you want ?"
                      // onConfirm={() => HandlePickUp(mailbox?.id)}
                      okText="Yes"
                      cancelText="No"
                      icon={null}
                    >
                      <span>
                        <MoveDown />
                      </span>
                    </Popconfirm>
                  </CustomTooltip>

                  <CustomTooltip text={"Shred"} marginTop="-25px">
                    <Popconfirm
                      title="Do you really want to call?"
                      // onConfirm={() => handleSherd()}
                      okText="Yes"
                      cancelText="No"
                      icon={null}
                    >
                      <span>
                        <Call />
                      </span>
                    </Popconfirm>
                  </CustomTooltip>
                  <CustomTooltip text={"Junk Mail"} marginTop="-25px">
                    <Popconfirm
                      title="Do you really want to mark this call as Junk?"
                      // onConfirm={() => handleJunck()}
                      okText="Yes"
                      cancelText="No"
                      icon={null}
                    >
                      <span>
                        <TrashIcon />
                      </span>
                    </Popconfirm>
                  </CustomTooltip>

                  <CustomTooltip text={"Edit Mail"} marginTop="-25px">
                    <span
                    // onClick={() => navigate(`/mailbox/edit/${mailbox?.id}`)}
                    >
                      <Printer />
                    </span>
                  </CustomTooltip>
                </div>
              </td>
            </tr>
          ))
        )}
        {/* {console.log(mailBoxData?.last_page, 'mailBoxData?.last_page')} */}
        <div className="table-footer">
          <button className="add-btn">
            <span className="btn-text">ADD NEW</span>
            <span className="btn-icon">
              <FaPlus />
            </span>
          </button>
          <PaginationContainer>
            <Pagination
              showSizeChanger
              // onChange={onShowSizeChange}
              defaultCurrent={5}
              nextIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="8"
                  viewBox="0 0 6 8"
                  fill="none"
                >
                  <path
                    d="M1.5 7L4.5 4L1.5 1"
                    stroke="#556373"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              prevIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="8"
                  viewBox="0 0 6 8"
                  fill="none"
                >
                  <path
                    d="M4.5 7L1.5 4L4.5 1"
                    stroke="#556373"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              total={2}
              className="pagination-div"
            />
          </PaginationContainer>
        </div>
      </Table>
    </div>
  );
};

export default VoiceMailsTable;

import styled from 'styled-components'

const HeaderWrapper = styled.div`
  display: flex;
  /* width: ${(props) => (props.isCollopased ? '95.5%' : '82%')}; */
  width: -webkit-fill-available;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3eced;
  /* border-left: 1px solid #e3eced; */
  height: 42px;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 9;
  left: ${(props) => (props.isCollopased ? '67px;' : '272px;')};
  /* right: 0; */
  /* width: 95.5%; */
  margin: auto;
  /* .ant-dropdown {
    width: 187px !important;
  } */
`

const BreadcrumbContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 8px;
  .ant-breadcrumb ol {
    gap: 8px;
  }
  .ant-breadcrumb-link:hover {
    background: transparent;
  }
  .ant-breadcrumb a {
    display: flex;
    gap: 8px;
    align-items: center;
    svg {
      width: 12px;
      height: 12px;
    }
    span {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
`

const AvatarContainer = styled.div`
  display: flex;
  /* padding: 8px 26px 8px 16px; */
  align-items: center;
  /* gap: 5.333px; */
  :where(.css-dev-only-do-not-override-2rgkd4).ant-dropdown {
    display: flex;
    width: 184px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 0px 0px 12px 12px;
    border-right: 1px solid #cde8ed;
    border-bottom: 1px solid #cde8ed;
    border-left: 1px solid #cde8ed;
    background: var(--White-100, #fff);
    box-shadow: 0px 5px 10px 0px rgba(33, 85, 93, 0.06);
  }
  .avatar-wrapper {
    display: flex;
    padding: 8px 26px 8px 16px;
    align-items: center;
    gap: 5.333px;
    border-left: 1px solid var(--Soft-Blue-100, #eff5f6);
    .user-avatar {
      .avatar {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        border-radius: 37px;
      }
    }
    .labels {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 160% */

      .btn-title {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        font-family: Outfit;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 160% */
      }
      .anticon {
        width: 8px;
        height: 4px;
        svg {
          fill: #4cbbcb;
        }
      }
    }
  }
`
export { HeaderWrapper, BreadcrumbContainer, AvatarContainer }

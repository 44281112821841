import styled from 'styled-components'

const MainContainer = styled.div`
  max-width: 124px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d0dee1;
  background: #fff;
  z-index: 1;
  .custom-dropdown {
    position: relative;
    padding: 4px 11px 4px;
    width: 124px;
    height: 35px;
  }

  .dropdown-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    text-align: center;
    height: 100%;
    .cancel-btn:hover {
      background: var(--Blue-100, #4cbbcb);
      color: white;
      svg {
        width: 10px;
        height: 10px;
        fill: white;
      }
    }
    .cancel-btn {
      width: 17px;
      height: 14px;
      border-radius: 20px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 7px;
      font-size: 14px;
      font-weight: 600;
      svg {
        width: 10px;
        height: 10px;
      }
    }

    .placeholder {
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      width: 65px;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
    svg {
      width: 10px;
      height: 10px;
    }
  }

  .dropdown-header:hover {
    /* background-color: #f0f0f0; */
  }

  .dropdown-list {
    position: absolute;
    top: 64%;
    left: 0;
    width: 174px;
    list-style: none;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    /* border-radius: 0px 0px 12px 12px;
    border-right: 1px solid #cde8ed;
    border-bottom: 1px solid #cde8ed;
    border-left: 1px solid #cde8ed; */
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #cde8ed;
    background: var(--White-100, #fff);
    box-shadow: 0px 5px 10px 0px rgba(33, 85, 93, 0.06);
    li {
      display: flex;
      padding: 4px 8px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .dropdown-list li {
    padding: 5px;
    cursor: pointer;
  }

  .dropdown-list li svg path:hover {
    fill: #4cbbcb;
  }
  .dropdown-list li svg {
    width: 12px;
    height: 12px;
  }
  .dropdown-list li:hover {
    border-radius: 4px;
    background-color: #dbf1f5;
  }
`

export { MainContainer }

import styled from "styled-components";

const ListWrapper = styled.div`
  width: auto;
  height: 440px;
  background: white;
  display: flex;
  flex-direction: row;
  overflow: auto;
  z-index: 1000;
  gap: 10px;
`;

export { ListWrapper };

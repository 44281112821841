import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles";
import useGetData from "../../../../hooks/useGetData";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";

const ChatHeaderDropDown = ({
  selectedEmailId,
  setCurrentEmployeeImage,
  email,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { auth } = useSelector((state) => state);

  const { data: employees, error } = useGetData("users-home");

  useEffect(() => {
    if (employees && email?.data?.user_id) {
      // Look for the user that matches the email's user_id
      const matchingRole = employees.roles.find((role) =>
        role.users.some((user) => user.id === email.data.user_id)
      );

      if (matchingRole) {
        const matchingUser = matchingRole.users.find(
          (user) => user.id === email.data.user_id
        );
        if (matchingUser) {
          setSelectedUser(matchingUser);
          setCurrentEmployeeImage(matchingUser);
        }
      } else {
        // Fallback: if no matching user is found, select the first Admin as default
        const adminRole = employees.roles.find((role) => role.name === "Admin");
        if (adminRole && adminRole.users.length > 0) {
          setSelectedUser(adminRole.users[0]);
          setCurrentEmployeeImage(adminRole.users[0]);
        }
      }
    }
  }, [employees, email]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectUser = async (roleId, userId) => {
    const role = employees.roles.find((role) => role.id === roleId);
    const user = role.users.find((user) => user.id === userId);

    setSelectedUser(user);
    setCurrentEmployeeImage(user);

    setIsOpen(false);

    try {
      const response = await InstanceWithAuth.get(
        `${process.env.REACT_APP_API_BASE_URL}/support/emails/${selectedEmailId}/change-owner/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      // Handle the successful response
      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      // Handle errors
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message || "Something went wrong!",
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {selectedUser
              ? `${selectedUser.first_name} ${selectedUser.last_name}`
              : "Select Employee"}
          </div>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#4CBBCB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#556373"
                strokeOpacity="0.2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            {employees?.roles.map(
              (role) =>
                role.users.length > 0 && (
                  <li key={role.id} className="role-item">
                    <strong>{role.name}</strong>
                    <ul className="items">
                      {role.users.map((user) => (
                        <li
                          key={user.id}
                          className="dropdown-item"
                          onClick={() => handleSelectUser(role.id, user.id)}
                        >
                          {user.avatar ? (
                            <img
                              src={user.avatar}
                              alt={`${user.first_name} ${user.last_name}`}
                              className="user-avatar"
                            />
                          ) : (
                            <div className="user-initials">
                              {user?.first_name[0]}
                              {user?.last_name[0]}
                            </div>
                          )}
                          {user.first_name} {user.last_name}
                        </li>
                      ))}
                    </ul>
                  </li>
                )
            )}
          </ul>
        )}
      </div>
    </MainContainer>
  );
};

export default ChatHeaderDropDown;

import React from 'react'
import styled from 'styled-components'

const NameDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.val ? `${props.val}px` : '107px')};
  height: ${(props) => (props.val ? `${props.val}px` : '107px')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '24px')};
  border-radius: 50%;
  background-color: #4cbbcb;
  color: #fff;
  font-weight: 600;
`

export default function NameAvatar({ userName, val, fontSize }) {
  const GetInitialLetter = () => {
    if (!userName) return ''

    // Clean up the userName by removing extra spaces and newlines
    const cleanedUserName = userName.replace(/\s+/g, ' ').trim()
    const names = cleanedUserName.split(' ')

    if (names.length === 1) {
      // If there is only one name, return the first two letters of that name
      return names[0].slice(0, 2).toUpperCase()
    }

    // If there is a first name and a last name, ensure they exist and return the first letter of each
    const firstInitial = names[0] ? names[0][0].toUpperCase() : ''
    const lastInitial = names[1] ? names[1][0].toUpperCase() : ''

    return `${firstInitial}${lastInitial}`
  }

  const initials = GetInitialLetter()

  return (
    <NameDiv val={val} fontSize={fontSize}>
      {initials || 'N/A'}
    </NameDiv>
  )
}

import React from "react";
import "./styles";
import { Container, Title, UserAvatar } from "./styles";
import { useNavigate } from "react-router-dom";
import useGetData from "../../../../../hooks/useGetData";
import { useDispatch } from "react-redux";
import NameAvatar from "../../../../../common/NameAvatar";
import { LuExternalLink } from "react-icons/lu";
import { Skeleton } from "antd";

const SupportHeader = ({ userID, showDrawer, refetchData, fromTicket }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: contactData,
    loading: contactDataLoading,
    error: contactDataErro,
    refetchData: refetchConatctData,
  } = useGetData(`contacts/${userID}`);

  const handleRedirect = () => {
    navigate(`/users/${userID}`);
  };

  return (
    <Container>
      <Title>
        <div className="avatar-wrapper">
          <div className="user-img">
            {/* <img className="contact-image" src={contactData?.data?.avatar} alt="" /> */}

            {contactData?.data?.avatar ? (
              <NameAvatar
                val={40}
                userName={`${contactData?.data?.name || "User"}`}
              ></NameAvatar>
            ) : (
              <NameAvatar
                val={40}
                userName={`${contactData?.data?.name || "User"}`}
              ></NameAvatar>
            )}
          </div>

          <div className="name-wrapper">
            <span className="name">
              {contactData?.data?.first_name +
                " " +
                contactData?.data?.last_name}
            </span>
            <span className="desc">{contactData?.data?.company_name}</span>
            {!fromTicket && (
              <button onClick={showDrawer} className="btn">
                View Conatct Details
              </button>
            )}
          </div>
        </div>
        <div className="icons">
          <span className="icon" onClick={() => handleRedirect("1")}>
            <LuExternalLink size={17}></LuExternalLink>
          </span>
        </div>
      </Title>
    </Container>
  );
};

export default SupportHeader;

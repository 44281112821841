import React from 'react'
import { Container } from './styles'
import './styles'
import GetLogo from '../../getlogo/getlogo'
import InvoiceCompanyDropDown from '../../invoiceFiltersDropDown/invoiceCompanyDropDown/InvoiceCompanyDropDown'
import BoardCustomDropDown from '../boardCustomDropDown/BoardCustomDropDown'
const TicketCenterSearchBar = ({
  setTimeSearchParam,
  timeSearchParam,
  setQueryParam,
}) => {
  return (
    <Container>
      <div className="search">
        <span className="icon">{GetLogo('searchIcon')}</span>
        <input
          onChange={(e) => setQueryParam(e.target.value)}
          type="text"
          className="input"
          placeholder="Search |"
        />
      </div>
      <div className="filter">
        {/* <BoardCustomDropDown
          selectedStatusOption={timeSearchParam}
          setSelectedStatusOption={setTimeSearchParam}
          dropDownTitle="Board Options"
        ></BoardCustomDropDown> */}
      </div>
    </Container>
  )
}

export default TicketCenterSearchBar

import styled from 'styled-components'

const MainConatiner = styled.div`
  margin-top: 92px;
  background-color: #fff;
  min-height: calc(100vh - 92px);
  display: flex;
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`
const BoardContainer = styled.div`
  width: 100%;
  .kanban-board {
    span {
      font-weight: 500 !important;
    }
  }
  .react-trello-board {
    background-color: white;
    padding: 20px;
    /* border-radius: 10px; */
    width: 100%;
    padding-top: 0;
    height: calc(100vh - 250px);
    .rtd-card-close {
      display: none !important; /* Hides the close button */
    }
  }
  .react-trello-lane {
    border-radius: 8px;
    border: 1px solid #e3eaee;
    background: white;
  }
  .react-trello-lane:hover {
    border-radius: 8px;
    border: 1px solid #e3eaee;
    background: rgba(241, 244, 245, 0.8);
  }
  .lane-collapsed {
    width: 50px !important; /* Important to override any default */
    transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateX(-100%);
    overflow: hidden;
  }

  .lane-expanded {
    width: 300px !important; /* Important to override any default */
    transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateX(0);
    overflow: hidden;
  }
`
export { MainConatiner, BoardContainer }

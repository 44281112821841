import styled from "styled-components";

const MainConatiner = styled.div`
  padding: 40px 24px 40px 24px;
  .button-div {
    display: flex;
    justify-content: end;
    margin-right: 15px;
    margin-top: 9px;
    gap: 15px;
    width: 100%;
    padding-bottom: 50px;
    button {
      display: flex;
      height: 32px;
      min-width: 73px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .close-btn {
      background-color: #fff;
      color: gray;
      border: 1px solid gray;
    }
  }
`;

const Container = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .title-main {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 528px;
    width: 100%;
    align-self: stretch;

    .title {
      margin: 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }
  .form-container {
    display: flex;
    max-width: 548px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    gap: 10px;

    .form-main {
      display: flex;
      flex-direction: column;
      height: 66px;
      max-width: 548px;
      width: 100%;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        height: 134px;
        max-width: 548px;
        width: 100%;
        .form-items {
          margin-bottom: 0;
          height: 64px;
          max-width: 548px;
        }

        .input {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          width: 548px;
          height: 41px;
          border-radius: 4px;
          box-shadow: none;
          border-color: unset;
          border: 1px solid #d0dee1;
          background: #fff;
        }

        .input::placeholder {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .input:focus::placeholder {
          color: transparent;
        }
        .input:focus {
          box-shadow: none;
        }
        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input {
          border-color: unset;
          border: 1px solid #d0dee1;
          box-shadow: none;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
          font-weight: 400;
          font-family: Outfit;
        }
        .ant-form-item .ant-form-item-label > label::after {
          content: "";
        }
        .ant-form-item-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
          align-self: stretch;

          label {
            height: unset;
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
            align-self: stretch;
          }
        }
        .location {
          align-items: center;
          min-width: 548px;
          height: 41px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          height: auto;
          min-height: 42px;
          padding-top: 4px;
          padding-right: 15px !important;

          .ant-select-selector {
            border: none;
            outline: none;
            box-shadow: none;
          }
          .location::placeholder {
            color: var(--Gray-40, rgba(85, 99, 115, 0.4));
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .location:focus::placeholder {
            color: transparent;
          }
          .ant-select-selection-item {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }
  }
  .welcome-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: 120px;
    max-width: 548px;
    margin-top: 10px;
    width: 100%;
    align-self: stretch;

    .form-items {
      margin-bottom: 0;
      height: 64px;
      max-width: 548px;
    }

    .input {
      display: flex;
      height: 85px;
      padding: 12px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      width: 548px;
      background: #fff;
      resize: none;
    }

    .input::placeholder {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }
    .input:focus::placeholder {
      color: transparent;
    }
    .input:focus {
      box-shadow: none;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input {
      border-color: unset;
      border: 1px solid #d0dee1;
      box-shadow: none;
    }
    .ant-form-item-explain-error {
      font-size: 10px;
      font-weight: 400;
      font-family: Outfit;
    }
    .ant-form-item .ant-form-item-label > label::after {
      content: "";
    }
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: 100%;
      align-self: stretch;

      label {
        height: unset;
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        align-self: stretch;
      }
    }
  }
`;

const ProductContainer = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  /* border: 1px solid #d0dee1; */
  /* background: #f3f6f7; */
  max-width: 532px;
  width: 100% !important;
  /* height: 380px; */

  .form {
    display: flex;
    /* padding-top: 20px; */
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    align-self: stretch;
    /* border-top: 1px solid rgba(208, 222, 225, 0.32); */
    .title {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
    .ant-form-item {
      height: 61px;
      margin-bottom: unset;
    }

    .ant-form-item-row {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      gap: 4px;

      align-self: stretch;
      margin-bottom: 0;

      label {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: auto;
        /* line-height: 14.4px; */
      }

      .username-input {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        width: 532px;
        /* width: 100%; */
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        height: 41px;
      }
      .form-select:hover {
        /* border: 1px solid #d0dee1; */
        /* border: none; */
      }
      .half-input {
        border: 1px solid #d0dee1;
        border-radius: 4px;

        /* padding: 12px; */
        background: #fff;
      }

      .ant-select-selector {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        width: 528px;
        /* width: 100%; */
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        height: 38px;
      }

      .username-input::placeholder {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .half-input::placeholder {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .ant-select-single {
        height: 41px;
      }
      .username-input:focus::placeholder {
        color: transparent;
      }
      .username-input:focus {
        box-shadow: none;
      }

      label::after {
        content: "";
      }
    }
    .countary-state {
    }
  }
`;

export { Container, MainConatiner, ProductContainer };

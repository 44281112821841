import styled from "styled-components";

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .title-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    max-width: 548px;
    width: 100%;
    align-self: stretch;

    .title {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }

  .form-container {
    display: flex;
    max-width: 548px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: auto;

    .form-main {
      display: flex;
      flex-direction: column;
      height: 66px;
      max-width: 548px;
      width: 100%;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      margin-top: 10px;

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        height: 134px;
        max-width: 548px;
        width: 100%;
        gap: 16px;

        .form-items {
          margin-bottom: 0;
          height: 64px;
          max-width: 548px;
        }

        .input {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          width: 548px;
          height: 41px;
          border-radius: 4px;
          box-shadow: none;
          border-color: unset;
          border: 1px solid #d0dee1 !important;
          background: #fff;
        }

        .input::placeholder {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .input:focus::placeholder {
          color: transparent;
        }
        .input:focus {
          box-shadow: none;
        }
        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input {
          border-color: unset;
          border: 1px solid #d0dee1;
          box-shadow: none;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
          font-weight: 400;
          font-family: Outfit;
        }
        .ant-form-item .ant-form-item-label > label::after {
          content: "";
        }
        .ant-form-item-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
          align-self: stretch;

          label {
            height: unset;
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
            align-self: stretch;
          }
        }
        .location {
          align-items: center;
          min-width: 548px;
          height: 41px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          height: auto;
          min-height: 42px;
          padding-top: 4px;
          padding-right: 15px !important;

          .ant-select-selector {
            border: none;
            outline: none;
            box-shadow: none;
            .ant-select-selection.ant-select-selection--single {
              background: green;
            }
          }
        }
        .location::placeholder {
          color: var(--Gray-40, rgba(85, 99, 115, 0.4));
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .location:focus::placeholder {
          color: transparent;
        }
        .ant-select-selection-item {
          ${
            "" /* color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; */
          }
        }
      }
    }
  }
  .welcome-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: 120px;
    max-width: 548px;
    margin-top: 10px;
    width: 100%;
    align-self: stretch;

    .form-items {
      margin-bottom: 0;
      height: 64px;
      max-width: 548px;
    }

    .input {
      display: flex;
      height: 85px;
      padding: 12px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      width: 548px;
      background: #fff;
      resize: none;
    }

    .input::placeholder {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }
    .input:focus::placeholder {
      color: transparent;
    }
    .input:focus {
      box-shadow: none;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input {
      border-color: unset;
      border: 1px solid #d0dee1;
      box-shadow: none;
    }
    .ant-form-item-explain-error {
      font-size: 10px;
      font-weight: 400;
      font-family: Outfit;
    }
    .ant-form-item .ant-form-item-label > label::after {
      content: "";
    }
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: 100%;
      align-self: stretch;

      label {
        height: unset;
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        align-self: stretch;
      }
    }
  }
`;

const ProductContainer = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #f3f6f7;
  max-width: 528px;
  width: 100%;
  height: auto;

  .add-products {
    max-width: 500px;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .product-title {
      display: flex;
      width: 242.5px;
      align-items: center;
      gap: 27px;
      width: 242.5px;
      max-width: 100%;

      .title {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        line-height: 120%; /* 16.8px */
      }
    }
    .add-prduct-btn {
      display: flex;
      padding: 6px 12px;
      align-items: center;
      gap: 12px;
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;

      .btn-icon {
        svg {
          width: 16px;
          height: 16px;
          fill: #4cbbcb;
          color: #4cbbcb;
        }
      }

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
  }

  .product-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    max-width: 528px;
    width: 100%;
    height: auto;

    .item-main {
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      align-self: stretch;
      height: 48px;
      max-width: 488px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        width: 100%;
        .labels {
          display: flex;
          width: 320px;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;

          .item-title {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }

          .mailbox-title {
            color: var(--Gray-40, rgba(85, 99, 115, 0.4));
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
        .item-title {
          margin: 0;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }

        .delete-icon {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;

          svg {
            width: 16px;
            height: 16px;
            fill: #4cbbcb;
            color: #4cbbcb;
          }
        }
      }
    }
  }
`;

const MainConatiner = styled.div`
  .button-div {
    display: flex;
    justify-content: end;
    margin-top: 9px;
    gap: 15px;
    padding-right: 35px;
    .close-button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border-radius: 3px;
      border: 1px solid #d0dee1;
      background: #fff;
      cursor: pointer;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
`;

export { Container, ProductContainer, MainConatiner };

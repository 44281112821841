import React, { useState, useEffect, useRef } from "react";
import { MainContainer, TabContainer, TabButton, TabContent } from "./styles";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import FirstColumn from "../../components/support/FirstColumn/FirstColumn";
import SecondColumn from "../../components/support/SecondColumn/SecondColumn";
import ThirdColumn from "../../components/support/ThirdColumn/ThirdColumn";
import FourthColumn from "../../components/support/FourthColumn/FourthColumn";
import EmailComposer from "../../components/support/FirstColumn/composer/EmailComposer";
import ContractedChats from "../../components/support/contractedChats/ContractedChatss";

import { Drawer, Tabs } from "antd";
import AssociateContact from "../../components/support/AssociateContact/AssociateContact";
import { CloseOutlined } from "@ant-design/icons";

const Support = () => {
  const [selectedFolder, setSelectedFolder] = useState("Inbox");
  const [selectedEmailOrChat, setSelectedEmailOrChat] = useState(null);
  const [status, setStatus] = useState(0);
  const [isAllClosedSelected, setIsAllClosedSelected] = useState(false);
  const [isComposerVisible, setIsComposerVisible] = useState(false);
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("addExisting");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const refetchEmailsRef = useRef(null);
  const refetchCountRef = useRef(null);
  const refetchEmailRef = useRef(null);

 
  const showDrawerContactDrawer = () => {
    setOpenContactDrawer(true);
  };
  const onCloseContactDrawer = () => {
    setOpenContactDrawer(false);
  };

  const handleFolderSelect = (folder) => {
    setIsInitialLoad(true);
    if (folder === "All closed") {
      setSelectedFolder("inbox");
      setStatus(1);
      setIsAllClosedSelected(true);
    } else {
      setSelectedFolder(folder);
      setStatus(0);
      setIsAllClosedSelected(false);
    }
    setSelectedEmailOrChat(null);
  };

  useEffect(() => {
    setSelectedEmailOrChat(null);
  }, [selectedFolder]);

  const handleEmailSelect = (emailOrChatId) => {
    setSelectedEmailOrChat(emailOrChatId);
  };

  const handleOwnerChange = (event) => {
    const newOwner = event.target.value;
  };

  const openComposer = () => {
    setIsComposerVisible(true);
  };

  const closeComposer = () => {
    setIsComposerVisible(false);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <div style={{ position: "relative" }}>
      <CommonHaeder firstBcItem={"Support"} firstBcIcon={"support"} />
      <MainContainer>
        <div className="first-column">
          <FirstColumn
            status={status}
            selectedFolder={selectedFolder}
            onFolderSelect={handleFolderSelect}
            isAllClosedSelected={isAllClosedSelected}
            openComposer={openComposer}
            selectedEmailId={selectedEmailOrChat}
            setRefetchCount={(refetchFn) => {
              refetchCountRef.current = refetchFn;
            }}
          />
        </div>
        <div className="second-column">
          <SecondColumn
            isInitialLoad={isInitialLoad}
            setIsInitialLoad={setIsInitialLoad}
            status={status}
            selectedFolder={selectedFolder}
            onEmailSelect={handleEmailSelect}
            selectedEmailId={selectedEmailOrChat}
            setRefetchEmails={(refetchFn) => {
              refetchEmailsRef.current = refetchFn;
            }}
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
          />
        </div>
        <div className="third-column">
          <ThirdColumn
            selectedEmailId={selectedEmailOrChat}
            onOwnerChange={handleOwnerChange}
            refetchEmails={refetchEmailsRef.current}
            refetchCount={refetchCountRef.current}
            setSelectedEmailOrChat={setSelectedEmailOrChat}
            showDrawerContactDrawer={showDrawerContactDrawer}
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
          />
        </div>
        <div className="fourth-column">
          <FourthColumn
            showDrawerContactDrawer={showDrawerContactDrawer}
            onCloseContactDrawer={onCloseContactDrawer}
            selectedEmailId={selectedEmailOrChat}
            setSelectedEmailOrChat={setSelectedEmailOrChat}
            refetchCount={refetchCountRef.current}
            refetchEmails={refetchEmailsRef.current}
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
            selectedFolder={selectedFolder}
            setRefetchEmail={(refetchFn) => {
              refetchEmailRef.current = refetchFn;
            }}
          />
        </div>

        <EmailComposer
          refetchEmails={refetchEmailsRef.current}
          selectedEmailId={selectedEmailOrChat}
          visible={isComposerVisible}
          onClose={closeComposer}
        />
       
      </MainContainer>
      <Drawer
        className="drawer"
        width={600}
        padding={0}
        paddingLG={0}
        paddingXS={0}
        onClose={onCloseContactDrawer}
        open={openContactDrawer}
        headerStyle={{
          backgroundColor: "#4cbbcb",
          color: "#fff",
        }}
        closeIcon={
          <CloseOutlined style={{ color: "white", fontSize: "bold" }} />
        }
        title={
          activeTab === "addExisting"
            ? "Add existing Contact"
            : "Create and associate contact"
        }
      >
        {/* Custom tab navigation */}
        <TabContainer>
          <TabButton
            isActive={activeTab === "addExisting"}
            onClick={() => handleTabChange("addExisting")}
          >
            Add existing
          </TabButton>
          <TabButton
            isActive={activeTab === "createNew"}
            onClick={() => handleTabChange("createNew")}
          >
            Create new
          </TabButton>
        </TabContainer>

        <TabContent>
          {activeTab === "addExisting" && (
            <AssociateContact
              type="addExisting"
              onCloseContactDrawer={onCloseContactDrawer}
              selectedEmailOrChat={selectedEmailOrChat}
              refetchEmails={refetchEmailsRef.current}
              refetchEmail={refetchEmailRef.current}
            />
          )}
          {activeTab === "createNew" && (
            <AssociateContact
              type="createNew"
              onCloseContactDrawer={onCloseContactDrawer}
              selectedEmailOrChat={selectedEmailOrChat}
              refetchEmails={refetchEmailsRef.current}
              refetchEmail={refetchEmailRef.current}
            />
          )}
        </TabContent>
      </Drawer>
    </div>
  );
};

export default Support;

import React, { useState, useEffect, useRef } from 'react'
import './styles'
import { Container, FormContainer } from './styles'
import { Skeleton, Form, Input, Tooltip } from 'antd'
import { Collapse } from 'antd'
import useGetData from '../../../../../hooks/useGetData'
import FormatDate from '../../../../../common/FormatDate'

const { Panel } = Collapse

const Account = ({
  userID,
  form,
  handleFormItemChange,
  setPrimaryEmail,
  setShowSaveBtn,
  formUpdatingloading,
  setInitialValues,
  userData,
}) => {
  const { data, loading, error, refetchData } = useGetData(`contacts/${userID}`)
  const [collopasedKey, setCollopasedKey] = useState(false)
  const [tooltipText, setTooltipText] = useState('Click to copy')
  const inputRef = useRef(null)

  useEffect(() => {
    setShowSaveBtn(collopasedKey)
  }, [collopasedKey, setShowSaveBtn])

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        firstName: userData.data.first_name,
        lastName: userData.data.last_name,
        primaryEmail: userData.data.email,
        loginEmail: userData.data.email,
        number: userData.data.phone,
      })
    }
  }, [userData, form])

  useEffect(() => {
    if (userData) {
      const initialData = {
        firstName: userData.data?.first_name,
        lastName: userData.data?.last_name,
        number: userData.data?.phone,
        primaryEmail: userData.data.email,
      }
      setInitialValues(initialData)
    }
  }, [userData])

  const handleCopy = () => {
    if (inputRef?.current) {
      const textToCopy = inputRef?.current?.textContent
      navigator.clipboard.writeText(textToCopy)
      setTooltipText('Copied')
      setTimeout(() => {
        setTooltipText('Click to Copy')
      }, 2000)
    }
  }

  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Account" key="1" className="account-panel">
          <FormContainer>
            <Skeleton
              rows={10}
              paragraph={{ rows: 11 }}
              loading={formUpdatingloading || loading}
              active
            >
              <Form
                className="form-main"
                requiredMark={false}
                form={form}
                onValuesChange={handleFormItemChange}
              >
                <div className="custom-id-main">
                  <span className="label">Customer ID</span>
                  <div className="custom-id">
                    <span className="title" ref={inputRef}>
                      {userData?.data?.id}
                    </span>
                    <Tooltip title={tooltipText}>
                      <span className="btn" onClick={handleCopy}>
                        <span className="btn-text">Copy</span>
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  className="input-label"
                >
                  <Input className="username-input" placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  className="input-label"
                >
                  <Input className="username-input" placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                  label="Primary Email Address"
                  name="primaryEmail"
                  className="input-label"
                  onChange={(e) => setPrimaryEmail(e.target.value)}
                >
                  <Input className="username-input" placeholder="Email" />
                </Form.Item>
                {/* <Form.Item
                  label="Dashboard Login Email "
                  name="loginEmail"
                  className="input-label"
                  onChange={(e) => setDashboardEmailEmail(e.target.value)}
                >
                  <Input className="username-input" placeholder="Email" />
                </Form.Item> */}
                <Form.Item
                  label="Personal Phone Number"
                  name="number"
                  className="input-label"
                >
                  <Input
                    className="username-input"
                    placeholder="+54 5491140449157"
                  />
                </Form.Item>
              </Form>
            </Skeleton>
          </FormContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}

export default Account

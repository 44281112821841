import React from "react";
import AvatarContainer from "./styles";

const Avatarr = ({ currentEmployeeImage }) => {
  const firstNameInitial = currentEmployeeImage?.first_name?.[0] || "";
  const lastNameInitial = currentEmployeeImage?.last_name?.[0] || "";


  return (
    <AvatarContainer>
      <div className="avatarContainer">
        {currentEmployeeImage?.avatar ? (
          <img
            className="image-style"
            src={currentEmployeeImage?.avatar}
            alt={`${currentEmployeeImage?.first_name || ""} ${
              currentEmployeeImage?.last_name || ""
            }`}
          />
        ) : firstNameInitial || lastNameInitial ? (
          <div className="user-initials">
            {firstNameInitial}
            {lastNameInitial}
          </div>
        ) : (
          <></>
        )}
      </div>
    </AvatarContainer>
  );
};

export default Avatarr;

import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'
const PillContainer = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  color: #333;
  padding: 0.2em 1.5em;
  border-radius: 9999px;
  font-size: 0.775em;
  margin: 0.4em;
`

const Text = styled.span`
  margin-right: 0.5em;
`

const CrossIcon = styled(FaTimes)`
  cursor: pointer;
`
export { PillContainer, Text, CrossIcon }

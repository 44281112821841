import React from 'react'
import GetLogo from '../../components/getlogo/getlogo'
import { useDispatch } from 'react-redux'
import SmallSidebar from '../../components/siderbarComponents/smallSidebar/SmallSidebar'
import LargeSidebar from '../../components/siderbarComponents/largeSidebar/LargeSidebar'
import { handleLogoutUser } from '../../redux/auth/authSlice'
import { handleCollapsed } from '../../redux/app/appSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useGetData from '../../hooks/useGetData'
import SubMenu from 'antd/es/menu/SubMenu'
import { CiViewBoard } from 'react-icons/ci'
function SideBar({ collapsed }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { auth } = useSelector((state) => state)
  const {
    data: mailCountData,
    error: mailCountError,
    loading: mailCountDataLoading,
  } = useGetData('virtual-mails/counter')

  const logOutuser = () => {
    dispatch(handleLogoutUser())
    navigate('/')
  }
  const handleCollapsedSideBar = () => {
    dispatch(handleCollapsed())
  }

  const menuItems = [
    {
      key: '1',
      icon: GetLogo('mailbox'),
      label: 'Virtual Mailbox',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: mailCountData?.data?.all,
      path: `/mailbox/tab/${1}`,
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Virtual Mailbox app'
      )?.status,
      isVisible: auth?.userObj?.role_name == 'Mailbox Operator' ? true : false,
    },
    {
      key: '2',
      icon: GetLogo('live answering'),
      label: 'Live Answering',
      className: 'menu-item',
      isBadge: true,
      count: 10,
      path: '/live-answering',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Live Answering app'
      )?.status,
      isVisible: auth?.userObj?.role_name == 'Call Operator' ? true : false,
    },
    {
      key: '3',
      icon: GetLogo('location'),
      label: 'Locations',
      className: 'menu-item',
      onClick: '',
      isBadge: false,
      count: 10,
      path: '/all-locations',
      access: true,
      isVisible: auth?.userObj?.role_name == 'Admin' ? true : false,
    },
    {
      key: '4',
      icon: GetLogo('plains and products'),
      label: 'Plans & Products',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      path: '/plan-and-products',
      access: true,
      isVisible: auth?.userObj?.role_name == 'Admin' ? true : false,
    },
    {
      key: '5',
      icon: GetLogo('user'),
      label: 'Users',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      path: '/all-users',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'User/Company Account Management'
      )?.status,
      isVisible:
        auth?.userObj?.role_name == 'Admin' ||
        auth?.userObj?.role_name == 'Manager'
          ? true
          : false,
    },
    {
      key: '6',
      icon: GetLogo('companies'),
      label: 'Companies',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      path: '/companies',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'User/Company Account Management'
      )?.status,
      isVisible:
        auth?.userObj?.role_name == 'Admin' ||
        auth?.userObj?.role_name == 'Manager'
          ? true
          : false,
    },
    {
      key: '7',
      icon: GetLogo('employees'),
      label: 'Employees',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      path: '/employees/all-employees',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'User Permissions and Role Assignment'
      )?.status,
      isVisible: auth?.userObj?.role_name == 'Admin' ? true : false,
    },
    {
      key: '8',
      icon: GetLogo('billing'),
      label: 'Billings',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      path: '/all-invoices',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Billing and Payments'
      )?.status,
      isVisible:
        auth?.userObj?.role_name == 'Admin' ||
        auth?.userObj?.role_name == 'Manager'
          ? true
          : false,
    },
    {
      key: '9',
      icon: GetLogo('reports'),
      label: 'Reports',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      path: '',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Reports and Analytics'
      )?.status,
      isVisible: auth?.userObj?.role_name == 'Admin' ? true : false,
    },
    {
      key: '10',
      icon: GetLogo('support'),
      label: 'Support Inbox',
      className: 'menu-item',
      isBadge: true,
      count: 10,
      path: '/support',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Customer Support'
      )?.status,
      isVisible:
        auth?.userObj?.role_name == 'Admin' ||
        auth?.userObj?.role_name == 'Manager'
          ? true
          : false,
    },
    {
      key: '11',
      icon: GetLogo('logTickets'),
      label: 'Support Tickets',
      className: 'menu-item',
      isBadge: true,
      count: 10,
      path: '/ticketcenter',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Customer Support'
      )?.status,
      isVisible:
        auth?.userObj?.role_name == 'Admin' ||
        auth?.userObj?.role_name == 'Manager'
          ? true
          : false,
    },
    {
      key: '12',
      icon: GetLogo('scanMail'),
      label: 'Scan requests',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: 10,
      path: `/mailbox/tab/${2}`,
      access: true,
      isVisible: auth?.userObj?.role_name == 'Mailbox Operator' ? true : false,
    },
    {
      key: '13',
      icon: GetLogo('forwardMail'),
      label: 'Forward requests',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: mailCountData?.data?.is_forward_complete,
      path: `/mailbox/tab/${3}`,
      access: true,
      isVisible: auth?.userObj?.role_name == 'Mailbox Operator' ? true : false,
    },
    {
      key: '14',
      icon: GetLogo('sherdMail'),
      label: 'Shred requests',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: mailCountData?.data?.is_shred_complete,
      path: `/mailbox/tab/${4}`,
      access: true,
      isVisible: auth?.userObj?.role_name == 'Mailbox Operator' ? true : false,
    },
    {
      key: '15',
      icon: GetLogo('pickUpMail'),
      label: 'Pick up requests',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: mailCountData?.data?.is_pick_up_complete,
      path: `/mailbox/tab/${5}`,
      access: true,
      isVisible: auth?.userObj?.role_name == 'Mailbox Operator' ? true : false,
    },
    {
      key: '16',
      icon: GetLogo('junckMail'),
      label: 'Junk requests',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: mailCountData?.data?.is_junk,
      path: `/mailbox/tab/${6}`,
      access: true,
      isVisible: auth?.userObj?.role_name == 'Mailbox Operator' ? true : false,
    },
    {
      key: '17',
      icon: GetLogo('depositMail'),
      label: 'Deposit requests',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: mailCountData?.data?.is_check_deposit_completed,
      path: `/mailbox/tab/${7}`,
      access: true,
      isVisible: auth?.userObj?.role_name == 'Mailbox Operator' ? true : false,
    },
    {
      key: '18',
      icon: GetLogo('setting'),
      label: 'Settings',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      path: '',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Settings & Configurations'
      )?.status,
      isVisible: auth?.userObj?.role_name == 'Admin' ? true : false,
      hasSubmenu: true,
      SubMenuOptions: [
        {
          key: '1',
          label: 'Product Settings',
          className: 'menu-item',
          path: '/settings/product-settings',
        },
        {
          key: '2',
          label: 'General Settings',
          className: 'menu-item',
          path: '/settings/general-settings',
        },
      ],
    },
  ]

  const secondMenuitems = [
    {
      key: '1',
      icon: GetLogo('mailbox'),
      label: 'Virtual Mailbox',
      className: 'menu-item',
      onClick: '',
      isBadge: true,
      count: mailCountData?.data?.all,
      path: '/mailbox',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Virtual Mailbox app'
      )?.status,
      isVisible:
        auth?.userObj?.role_name == 'Admin' ||
        auth?.userObj?.role_name == 'Manager'
          ? true
          : false,
    },
    {
      key: '2',
      icon: GetLogo('live answering'),
      label: 'Live Answering',
      className: 'menu-item',
      isBadge: true,
      count: 10,
      path: '/live-answering',
      access: auth?.userObj?.userPermissions?.find(
        (item) => item?.name == 'Live Answering app'
      )?.status,
      isVisible:
        auth?.userObj?.role_name == 'Admin' ||
        auth?.userObj?.role_name == 'Manager'
          ? true
          : false,
    },
    {
      key: '3',
      icon: GetLogo('logTickets'),
      label: 'Log Ticket',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      access: true,
      isVisible: true,
    },
    {
      key: '4',
      icon: GetLogo('supportChat'),
      label: 'Chat with Manager',
      className: 'menu-item',
      isBadge: false,
      count: 10,
      access: true,
      isVisible: true,
    },
  ]

  return (
    <>
      {collapsed ? (
        <SmallSidebar
          menuItems={menuItems?.filter(
            (item) => item?.access == true && item?.isVisible == true
          )}
          secondMenuitems={secondMenuitems?.filter(
            (item) => item?.access == true && item?.isVisible == true
          )}
          handleCollapsed={handleCollapsedSideBar}
          logOutuser={logOutuser}
          collapsed={collapsed}
        />
      ) : (
        <LargeSidebar
          menuItems={menuItems?.filter(
            (item) => item?.access == true && item?.isVisible == true
          )}
          secondMenuitems={secondMenuitems?.filter(
            (item) => item?.access == true && item?.isVisible == true
          )}
          // menuItems={menuItems}
          // secondMenuitems={secondMenuitems}
          handleCollapsed={handleCollapsedSideBar}
          logOutuser={logOutuser}
          collapsed={collapsed}
        />
      )}
    </>
  )
}

export default SideBar

import React, { Fragment, useState, useEffect } from 'react'
import arrow from '../../assets/arrow.png'
import { useDispatch, useSelector } from 'react-redux'
import { handleloginUser } from '../../redux/auth/authSlice'
import { InstanceWithAuth } from '../../App'
import AuthHeader from '../../components/auth/AuthHeader'
import { useNavigate } from 'react-router-dom'
import { Container, ImgConatiner, LoginFormContainer } from './styles'
import ReactTostify from '../../common/ReactTostify'
import Spinner from '../../common/Spinner'
import { Form, Input } from 'antd'
import { handleLogin } from '../../redux/auth/authSlice'
import { handleCurrentPage } from '../../redux/app/appSlice'
import Swal from 'sweetalert2'

function Login() {
  const { auth } = useSelector((state) => state)
  const [passwordPlaceholder, setPasswordPlaceholder] = useState('Password ')
  const [usernamePlaceholder, setUsernamePlaceholder] = useState('Username')
  const [isLoading, setIsLoading] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(handleCurrentPage('login'))
  }, [])
  console.log(auth, 'auth---')
  useEffect(() => {
    if (auth?.isLogin && false) {
      navigate('/employees/all-employees')
      dispatch(handleCurrentPage('Employees'))
    }
  }, [])
  const handleUserNameFocus = () => {
    setUsernamePlaceholder('')
  }
  const handlePasswordNameFocus = () => {
    setPasswordPlaceholder('')
  }
  if (auth?.isLogin && auth?.userObj?.role_name == 'Mailbox Operator') {
    navigate(`/mailbox/tab/${1}`)
    dispatch(handleCurrentPage('Virtual Mailbox'))
  } else if (auth?.isLogin && auth?.userObj?.role_name != 'Mailbox Operator') {
    navigate('/employees/all-employees')
    dispatch(handleCurrentPage('Employees'))
  }

  const handleSubmit = async (values) => {
    let formData = new FormData()
    formData.append('email', values?.username)
    formData.append('password', values?.password)
    try {
      setIsLoading(true)
      const response = await InstanceWithAuth.post('login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false)
        dispatch(handleLogin(response?.data))
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
          timer: 1000,
          showConfirmButton: false,
        })
      }
    } catch (error) {
      setIsLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    }
  }
  return (
    <Fragment>
      <AuthHeader />
      <Container>
        <LoginFormContainer>
          <div className="title">Please, login to StartHub Admin.</div>
          <Form
            className="login-form"
            requiredMark={false}
            onFinish={handleSubmit}
          >
            <div className="inputs">
              <Form.Item
                className="form-item"
                label="Username"
                name="username"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email address!',
                  },
                  { required: true, message: 'Email is missing' },
                ]}
              >
                <Input
                  placeholder={usernamePlaceholder}
                  className="user-input"
                  onFocus={handleUserNameFocus}
                />
              </Form.Item>

              <Form.Item
                className="form-item"
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Password is missing' }]}
              >
                <Input.Password
                  placeholder={passwordPlaceholder}
                  className="user-input custom-input-password"
                  onFocus={handlePasswordNameFocus}
                />
              </Form.Item>

              <div
                onClick={() => navigate('/forget-password')}
                className="forget-password"
              >
                <p>Forgot Password?</p>
              </div>
            </div>

            <Form.Item>
              <button
                className="login-btn"
                type="submit"
                disabled={isLoading ? true : false}
              >
                <span className="login-label">
                  {isLoading ? <Spinner></Spinner> : 'Login'}
                </span>
              </button>
            </Form.Item>
          </Form>
        </LoginFormContainer>
        <ImgConatiner>
          <div className="img-div">
            <div className="img-wrapper">
              <img src={arrow}></img>
            </div>
          </div>
        </ImgConatiner>
      </Container>
    </Fragment>
  )
}
export default Login

import React, { useState } from "react";
import { Collapse, Pagination, Skeleton } from "antd";
import GetLogo from "../../../../getlogo/getlogo";

import {
  CompanyCards,
  Container,
  ContentContainer,
  SearchContainer,
  PaginationContainer,
} from "./styles";
import "./styles";
import useGetData from "../../../../../hooks/useGetData";

const { Panel } = Collapse;

const Support = ({ userID }) => {
  const [collopasedKey, setCollopasedKey] = useState(false);
  const {
    data: tickets,
    error: ticketsError,
    loading,
  } = useGetData(
    `support/tickets?order_by=created_at&order=DESC&company_id=${userID}`
  );

  const TruncateText = ({ text }) => {
    const maxLength = 50;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Support" key="1" className="account-panel">
          <ContentContainer>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="tickets">
                {tickets?.data?.map((ticket, index) => (
                  <div key={index} className="ticket">
                    <span className="name">{ticket?.name}</span>
                    <span className="des">
                      <TruncateText text={ticket?.description}></TruncateText>
                    </span>
                    <div className="make-row">
                      <div className="left">
                        <span className="priority-label">Priority: </span>
                        <span className="priority">{ticket?.priority}</span>
                      </div>

                      <div className="right">
                        {ticket?.is_closed ? (
                          <div className="in-active">
                            <span className="text">Open</span>
                          </div>
                        ) : (
                          <div className="active">
                            <span className="text">Open</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ContentContainer>
          {/* <ContentContainer>
            <SearchContainer>
              <div className="search">
                <span className="icon">{GetLogo("search")}</span>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search"
                />
              </div>
            </SearchContainer>
            {[1, 2, 3].map((item) => (
              <CompanyCards>
                <div className="company-labels">
                  <p className="company-name">Gonzalez LLC</p>
                  <span className="office">Problem with Mailbox</span>
                </div>
                <div className="time-details">
                  <p className="date">11 October 2023</p>
                  <div className="btns">
                    <button className="active-btn">
                      <span className="btn-text">Active</span>
                    </button>
                  </div>
                </div>
              </CompanyCards>
            ))}
            <CompanyCards>
              <div className="company-labels">
                <p className="company-name">Gonzalez LLC</p>
                <span className="office">Problem with Mailbox</span>
              </div>
              <div className="time-details">
                <p className="date">11 October 2023</p>
                <div className="btns">
                  <button className="pending-btn">
                    <span className="btn-text">Pending</span>
                  </button>
                </div>
              </div>
            </CompanyCards>
            <CompanyCards>
              <div className="company-labels">
                <p className="company-name">Gonzalez LLC</p>
                <span className="office">Problem with Mailbox</span>
              </div>
              <div className="time-details">
                <p className="date">11 October 2023</p>
                <div className="btns">
                  <button className="closed-btn">
                    <span className="btn-text">Closed</span>
                  </button>
                </div>
              </div>
            </CompanyCards>
            <PaginationContainer>
              <Pagination
                defaultCurrent={1}
                total={50}
                className="activity-pagination"
              />
            </PaginationContainer>
          </ContentContainer> */}
        </Panel>
      </Collapse>
    </Container>
  );
};

export default Support;

import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai"; // Trash icon
import { MdDone, MdReportProblem } from "react-icons/md"; // Spam icon
import "./styles";
import {
  Container,
  Dropdown,
  Loader,
  Button,
  MenuOption,
  IconWrapper,
  ReopenIconWrapper,
  ReopenIconWrapperr,
} from "./styles";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Popconfirm } from "antd";
import { PiArrowArcRightBold } from "react-icons/pi";
import { FaRegTrashCan } from "react-icons/fa6";
import { CiTrash } from "react-icons/ci";

const ColumnHeader = ({
  selectedEmailId,
  refetchEmails,
  setSelectedEmailOrChat,
  email,
  selectedEmails,
  setSelectedEmails,
  selectedFolder,
  refetchCount,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle menu click to toggle dropdown
  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const moveToFolder = async (folder) => {
    setIsLoading(true);
    try {
      let formData = new FormData();
      if (selectedEmails?.length <= 0) {
        formData.append(`email_ids[0]`, selectedEmailId);
      } else {
        selectedEmails.forEach((emailId, index) => {
          formData.append(`email_ids[${index}]`, emailId);
        });
      }
      const response = await InstanceWithAuth.post(
        `support/emails/move_to/${folder}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false);
        setIsOpen(false);
        refetchEmails();
        refetchCount();
        setSelectedEmails([]);

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
        setSelectedEmailOrChat(null);
      }
    } catch (error) {
      setIsLoading(false);
      setIsOpen(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  const handleCloseConversation = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      if (selectedEmails?.length <= 0) {
        formData.append(`email_ids[0]`, selectedEmailId);
      } else {
        selectedEmails.forEach((emailId, index) => {
          formData.append(`email_ids[${index}]`, emailId);
        });
      }
      const response = await InstanceWithAuth.post(
        `support/emails/move_to/close`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false);
        setIsOpen(false);
        refetchEmails();
        refetchCount();
        setSelectedEmails([]);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
        setSelectedEmailOrChat(null);
      }
    } catch (error) {
      setError(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConversation = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      if (selectedEmails?.length <= 0) {
        formData.append(`email_ids[0]`, selectedEmailId);
      } else {
        selectedEmails.forEach((emailId, index) => {
          formData.append(`email_ids[${index}]`, emailId);
        });
      }
      const response = await InstanceWithAuth.post(
        `support/emails/move_to/open`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false);
        setIsOpen(false);
        refetchEmails();
        refetchCount();
        setSelectedEmails([]);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
        setSelectedEmailOrChat(null);
      }
    } catch (error) {
      setError(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container ref={dropdownRef}>
      {selectedEmails?.length > 0 && (
        <>
          <button className="button" disabled={loading}>
            <Popconfirm
              title="Move to trash"
              description="Are you sure to trash?"
              onConfirm={() => moveToFolder("trash")}
              // onCancel={c}
              okText="Yes"
              cancelText="No"
            >
              <ReopenIconWrapperr>
                <ReopenIconWrapper>
                  <CiTrash size={16} />
                </ReopenIconWrapper>
                {loading ? "Loading" : "Move to trash"}
              </ReopenIconWrapperr>
            </Popconfirm>
          </button>
        </>
      )}

      <button className="button" disabled={loading}>
        {!email?.data?.status ? (
          <Popconfirm
            title="Close Conversation"
            description="Are you sure to close?"
            onConfirm={() => handleCloseConversation()}
            // onCancel={c}
            okText="Yes"
            cancelText="No"
          >
            <ReopenIconWrapperr>
              <ReopenIconWrapper>
                <MdDone size={16} />
              </ReopenIconWrapper>
              {loading ? "Closing" : "Close Conversation"}
            </ReopenIconWrapperr>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Reopen Conversation"
            description="Are you sure to reopen?"
            onConfirm={() => handleOpenConversation()}
            // onCancel={c}
            okText="Yes"
            cancelText="No"
          >
            <ReopenIconWrapperr>
              <ReopenIconWrapper>
                <PiArrowArcRightBold size={16} />
              </ReopenIconWrapper>
              {loading ? "Opening" : "Reopen Conversation"}
            </ReopenIconWrapperr>
          </Popconfirm>
        )}
      </button>

      {selectedEmails?.length <= 0 && (
        <>
          <Button onClick={handleMenuClick}>⋯</Button>
          {isOpen && (
            <Dropdown>
              {isLoading ? (
                <Loader>Loading...</Loader>
              ) : (
                <>
                  <MenuOption onClick={() => moveToFolder("trash")}>
                    <IconWrapper>
                      <AiOutlineDelete size={16} />
                    </IconWrapper>
                    Move to Trash
                  </MenuOption>

                  <MenuOption onClick={() => moveToFolder("spam")}>
                    <IconWrapper>
                      <MdReportProblem size={16} />
                    </IconWrapper>
                    Move to Spam
                  </MenuOption>
                </>
              )}
            </Dropdown>
          )}
        </>
      )}
    </Container>
  );
};

export default ColumnHeader;

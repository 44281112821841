import React, { useState, useRef, useEffect } from 'react'
import { MainContainer } from './styles'
import NameAvatar from '../../common/NameAvatar'
import { handleLogoutUser } from '../../redux/auth/authSlice' // Import CSS for styling

const ProfileDropDown = ({ options, onSelect, auth }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const dropdownRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    setSelectedOption(option)
    onSelect(option)
    setIsOpen(false)
  }
  const TruncateText = ({ text }) => {
    const maxLength = 20
    let truncatedText = text.substring(0, maxLength)
    if (truncatedText.length < text.length) {
      truncatedText += ''
    }
    return <span title={text}>{truncatedText}</span>
  }
  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-avatar">
            {auth?.userObj?.avatar ? (
              <div className="image-avatar">
                <img
                  src={auth?.userObj?.avatar}
                  alt="avatar"
                  className="avatar"
                />
              </div>
            ) : (
              <NameAvatar
                userName={auth?.userObj?.first_name}
                val={24}
                fontSize={11}
              ></NameAvatar>
            )}
          </div>
          <div className="user-name">
            <TruncateText
              text={`${auth?.userObj?.first_name} ${auth?.userObj?.last_name}`}
            ></TruncateText>
          </div>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#4CBBCB"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#556373"
                stroke-opacity="0.2"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            {options.map((option, index) => (
              <li key={index} onClick={() => handleOptionSelect(option)}>
                {option.label}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <path
                    d="M4 10.5L8 6.5L4 2.5"
                    stroke="#CDE3E7"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
            ))}
          </ul>
        )}
      </div>
    </MainContainer>
  )
}

export default ProfileDropDown

import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown, Space, Switch } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ColumnHeaderContainer } from "./styles";
import GetLogo from "../../../getlogo/getlogo";

const ColumnHeader = ({ department, setDepartment, departments }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (departments && departments.length > 0) {
      setSelectedDepartment(departments[0]?.name); // Set the first department as default
    }
  }, [departments]);

  const departmentMenu = (
    <Menu>
      {departments?.map((dept, index) => (
        <Menu.Item
          onClick={() => {
            setSelectedDepartment(dept.name);
            setDepartment(dept);
          }}
          key={index}
        >
          {dept.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    if (isSearchVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (inputRef.current.value.trim() === "") {
          setIsSearchVisible(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleSwitchChange = (checked) => {
    setIsOnline(checked);
  };

  return (
    <ColumnHeaderContainer isSearchVisible={isSearchVisible}>
      <div className="make-row">
        <div className="search">
          {isSearchVisible ? (
            <div className="search-input">
              <span className="icon">{GetLogo("searchIcon")}</span>
              <input
                type="text"
                className="input"
                placeholder="Search"
                ref={inputRef}
              />
            </div>
          ) : (
            <div className="search-icon" onClick={handleSearchIconClick}>
              <i className="fas fa-search">{GetLogo("searchIcon")}</i>
              <div className="search-title">Search</div>
            </div>
          )}
        </div>

        {!isSearchVisible && (
          <Dropdown overlay={departmentMenu} trigger={["click"]}>
            <Space className="btn-title">
              {selectedDepartment}
              <DownOutlined />
            </Space>
          </Dropdown>
        )}
      </div>

      <div className="make-row">
        <div className="make-row1">
          <span
            className="circle"
            style={{
              backgroundColor: isOnline ? "lightgreen" : "#2b3746",
            }}
          ></span>
          <span className="status">
            {isOnline ? "You're online" : "You're away"}
          </span>
        </div>
        <Switch
          className="switch"
          checked={isOnline}
          onChange={handleSwitchChange}
        />
      </div>
    </ColumnHeaderContainer>
  );
};

export default ColumnHeader;

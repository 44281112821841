import React, { Fragment, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
  Navigate,
} from "react-router-dom";
import { Layout, theme } from "antd";
import { useSelector } from "react-redux";
import SideBar from "./sidebar/SideBar";
import AppRoutes from "../routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { handleLogoutUser } from "../redux/auth/authSlice";
import PageLoader from "../common/PageLoader";
import Login from "../pages/auth/Login";
import ContractedChats from "../components/support/contractedChats/ContractedChatss";
const { Content } = Layout;

const Layouts = () => {
  const { auth, app } = useSelector((state) => state);
  const [hasContractedChats, setHasContractedChats] = useState(false);

  useEffect(() => {
    const emailIdsArray =
      JSON.parse(localStorage.getItem("emailIdsArray")) || [];
    if (emailIdsArray.length > 0) {
      setHasContractedChats(true);
    }
  }, []);

  const dispatch = useDispatch();
  const {} = theme.useToken();
  const isTokenExpired = (token) => {
    // if (!token) return // No token, nothing to do
    // const decodedToken = jwtDecode(token)
    // const expirationTime = decodedToken?.exp * 1000
    // if (expirationTime < Date?.now()) {
    //   dispatch(handleLogoutUser())
    // }
  };
  useEffect(() => {
    if (isTokenExpired(auth?.userObj?.api_token)) {
      dispatch(handleLogoutUser());
    }
  }, []);
  return (
    <Router>
      <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
        {auth?.isLogin && app?.currentPage != "login" ? (
          <SideBar collapsed={app?.sidebarCollapsed}></SideBar>
        ) : (
          ""
        )}
        <Layout
          style={{
            marginLeft: !app?.sidebarCollapsed && auth?.isLogin ? "272px" : "",
          }}
        >
          <Content style={{ backgroundColor: "#fff" }}>
            <Suspense>
              <Routes>
                {AppRoutes?.map((route) => {
                  if (route?.isProtected && auth?.isLogin) {
                    return (
                      <Route
                        key={route?.id}
                        path={route?.pathName}
                        Component={route?.component}
                      />
                    );
                  } else if (!route?.isProtected) {
                    return (
                      <Route
                        key={route?.id}
                        path={route?.pathName}
                        Component={route?.component}
                      />
                    );
                  }
                })}
                {!auth?.isLogin && (
                  <Route path="*" element={<Navigate to="/login" />} />
                )}
              </Routes>
            </Suspense>
          </Content>
        </Layout>

        {/* {hasContractedChats && (
          <div
            style={{
              width: "auto",
              height: "440px",
              position: "absolute",
              zIndex: "1000",
              bottom: 0,
              right: 0,
              padding: "0 15px",
            }}
          >
            <ContractedChats />
          </div>
        )} */}
      </Layout>
    </Router>
  );
};
export default Layouts;

import React, { useState, useRef, useEffect } from 'react'
import { MainContainer } from './styles' // Import CSS for styling

const InvoiceDateFilters = ({
  selectedStatusOption,
  setSelectedStatusOption,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    setSelectedStatusOption(option)
    setIsOpen(false)
  }

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {selectedStatusOption ? selectedStatusOption : 'Select Status'}
          </div>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#4CBBCB"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#556373"
                stroke-opacity="0.2"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            <li onClick={() => handleOptionSelect('Last 6 months')}>
              Last 6 months
            </li>
            <li onClick={() => handleOptionSelect('Last 1 Year')}>
              Last 1 Year
            </li>
            <li onClick={() => handleOptionSelect('Last 2 Years')}>
              Last 2 Years
            </li>
          </ul>
        )}
      </div>
    </MainContainer>
  )
}

export default InvoiceDateFilters

import styled from "styled-components";

const ThirdColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background: #fafbfc;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 60px;
    right: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--Gray-5, rgba(85, 99, 115, 0.05));
  }
`;

export default ThirdColumnContainer;

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 339px;
  width: 100%;
  align-self: stretch;
  border-bottom: 1px solid #e3eced;
  padding-bottom: 30px;
`;

const Title = styled.div`
  display: flex;
  max-width: 323px;
  width: 100%;

  .avatar-wrapper {
    display: flex;
    width: 100%;

    .name-wrapper {
      padding-left: 10px;
      width: 100%;
      .name {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        margin: 0;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.77px;
        text-align: left;
        padding: 0;
        margin: 0;
        width: 100%;
      }
      .btn {
        cursor: pointer;
        display: flex;
        padding: 6px 12px;
        align-items: center;
        border-radius: 5px;
        border: 1px solid var(--Blue-100, #4cbbcb);
        background: var(--Blue-20, rgba(76, 187, 203, 0.2));
        box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
        color: var(--Blue-100, #4cbbcb);
        height: 26px;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        min-width: 125px;
        color: var(--Blue-100, #4cbbcb);
        margin-top: 5px;
      }
    }
  }

  .icons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    .icon {
      cursor: pointer;
    }
  }
`;

const UserAvatar = styled.div`
  display: flex;
  max-width: 339px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .user-img {
    width: 40px;
    object-fit: cover;
    height: 40px;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    text-align: center;
    .placeholder {
      border-radius: 100%;
      border: 1px solid var(--Gray-70, rgba(85, 99, 115, 0.7));
      width: 100%;
      height: 100%;
    }
  }

  .user-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .user-name {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 22.8px */
      letter-spacing: -0.38px;
      margin: 0px;
    }

    .user-id {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.32px;

      .id {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.32px;
      }
    }
  }
`;

const ContentContainer = styled.div`
  border-top: 1px solid #e3eced;
  display: flex;
  max-width: 339px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px;
  padding-left: 20px;
  align-self: center;
`;

export { Container, Title, UserAvatar, ContentContainer };

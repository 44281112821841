import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5.333px;
  z-index: 2;
  .custom-dropdown {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 28px;
    width: fit-content;
  }
  .dropdown-header {
    cursor: pointer;
    border-radius: 4px 4px 0px 4px;
    border: 1px solid #e3eced;
    display: flex;
    padding: 5px 8px;
    justify-content: flex-end;
    align-items: center;
    gap: 28px;
    width: 180px !important;
    justify-content: space-between;
    .user-name {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      text-align: right;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      .count {
        padding-top: 1px;
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        border-radius: 3px;
        background: var(--Blue-100, #4cbbcb);
        color: var(--White-100, #fff);
        text-align: center;
        font-family: Outfit;
        font-size: 7px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }

  .dropdown-header:hover {
    /* background-color: #f0f0f0; */
  }
  .dropdown-list input[type='checkbox'] {
    /* Your custom styles */
    border: 2px solid red !important; /* Border color */
    border-radius: 4px; /* Optional: Rounded corners */
  }

  .dropdown-list input[type='checkbox']:checked {
    /* Your custom styles for checked checkboxes */
    border-color: 2px solid red; /* Change border color when checked */
  }

  .dropdown-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    z-index: 2;
    width: 140px;
    padding: 16px;
    position: absolute;
    top: 50%;
    list-style: none;
    padding: 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius: 12px 0px 12px 12px;
    border: 1px solid #e3eced;
    background: var(--White-100, #fff);
    box-shadow: 0px 5px 10px 0px rgba(33, 85, 93, 0.06);
    width: 180px !important;
    .Filter-heading {
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      display: flex;
      padding: 0px 8px 16px 8px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid #e3eced;
      span {
        display: flex;
        align-items: center;
        gap: 8px;
        .checkmark::after {
          content: '';
          position: absolute;
          display: none;
          left: 5px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid #000;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
    li {
      display: flex;
      padding: 4px 8px;
      justify-content: start;
      align-items: center;
      align-self: stretch;

      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .dropdown-list li {
    padding: 8px;
    cursor: pointer;
  }

  .dropdown-list li svg path:hover {
    fill: #4cbbcb;
  }
  .dropdown-list li svg {
    width: 12px;
    height: 12px;
  }
  .dropdown-list li:hover {
    border-radius: 4px;
    background-color: #dbf1f5;
  }
`

export { MainContainer }

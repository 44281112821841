import React, { Fragment, useState, useEffect } from 'react'
import CommonHaeder from '../../components/header/commonHeader/CommonHaeder'
import TicketCenterSubHeader from '../../components/ticketCenter/ticketCenterSubHeader/TicketCenterSubHeader'
import TicketCenterFilters from '../../components/ticketCenter/ticketCenterFilters/TicketCenterFilters'
import { MainConatiner, BoardContainer } from './styles'
import Board from 'react-trello'
import { Avatar, Skeleton } from 'antd'
import TicketBoardCards from '../../components/ticketCenter/ticketCards/TicketBoardCards'
import TicketCenterSearchBar from '../../components/ticketCenter/ticketCenterSearchBar/TicketCenterSearchBar'
import useGetData from '../../hooks/useGetData'
import { useNavigate } from 'react-router-dom'
import { InstanceWithAuth } from '../../App'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
function TicketCenter() {
  const { auth } = useSelector((state) => state)
  const { app } = useSelector((state) => state)
  const [selectedPipeline, setSelectedPipeline] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [selectedPriority, setSelectedPriority] = useState('')
  const [selectedCreatedDate, setSelectedCreatedDate] = useState('')
  const [selectedLastActivityDate, setSelectedLastActivityDate] = useState('')
  const [queryParam, setQueryParam] = useState('')
  const [selectedDate, setSelectTime] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [filterDateType, setFilterDateType] = useState('')
  const {
    data: ticketBoardData,
    refetchData: refetchTikcedBoardData,
    loading: ticketBoardDataLoading,
  } = useGetData(
    `support/ticket-board?support_pipeline_id=${
      app?.userSelcetedPipeLine ? app?.userSelcetedPipeLine?.id : ''
    }&owner=${
      selectedOwner && selectedOwner?.name != 'all' ? selectedOwner?.id : ''
    }&priority=${
      selectedPriority?.name && selectedPriority?.name != 'all'
        ? selectedPriority?.name
        : ''
    }&query=${queryParam ? queryParam : ''}&from_date=${
      startDate && startDate != '-undefined-undefined' ? startDate : ''
    }&to_date=${endDate && endDate != '-undefined-undefined' ? endDate : ''}`
  )

  const navigate = useNavigate()
  useEffect(() => {
    refetchTikcedBoardData()
  }, [selectedPipeline, selectedOwner, selectedPriority, queryParam])
  useEffect(() => {
    if (selectedCreatedDate?.name == 'all') {
      setStartDate('')
      setEndDate('')
      refetchTikcedBoardData()
    }
  }, [selectedCreatedDate])
  const now = new Date()
  const cardStyle = {
    backgroundColor: 'rgba(76, 187, 203, 0.2)',
    border: '1px solid #4cbbcb',
    borderRadius: '8px',
    padding: '10px',
    margin: '5px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    color: '#2b3746',
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: 'normal',
    letterSpacing: '-0.49px',
    width: '250px',
    fontWeight: '500',
  }
  function timeAgo(dateString) {
    const date = new Date(dateString)

    if (isNaN(date.getTime())) {
      return 'Invalid date'
    }

    const now = new Date()
    const seconds = Math.floor((now - date) / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(seconds / 3600)
    const days = Math.floor(seconds / 86400)
    console.log(seconds, 'seconds')

    if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`
    }
    if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`
    }
    if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`
    }
    return 'Just now'
  }

  const mappedData = {
    lanes: Array.isArray(ticketBoardData?.data)
      ? ticketBoardData.data.map((stage) => ({
          id: stage?.id?.toString(),
          title: stage.title,
          label: `${stage?.cards?.length || 0}/${stage?.cards?.length || 0}`,
          style: {
            background: 'rgba(241, 244, 245, 0.8)',
            fontSize: '14px',
            color: '#2b3746',
            fontFamily: 'Outfit',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '-0.49px',
            height: '410px',
            width: '280px',
            // overflow: 'scroll',
          },
          cards: Array.isArray(stage?.cards)
            ? stage.cards.map((card) => ({
                id: card?.id?.toString(),
                title: card?.title,
                label: timeAgo(card?.label),
                description: card?.description,
                metadata: { sha: 'be312a1' },
                userAvatar: card?.userAvatar || null,
              }))
            : [],
        }))
      : [],
  }
  const handleCardClick = (cardId) => {
    navigate(`/tickets/${cardId}`)
  }
  const handleSubmit = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    if (sourceLaneId == targetLaneId) return null
    const data = {
      support_pipeline_id: app?.userSelcetedPipeLine?.id,
      support_pipeline_stage_id: targetLaneId,
    }
    const encodedData = new URLSearchParams(data).toString()
    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${cardId}`,
        encodedData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    }
  }
  return (
    <Fragment>
      <CommonHaeder firstBcItem={'Ticket Center'} firstBcIcon="logTickets" />
      <TicketCenterSubHeader />
      <MainConatiner>
        <TicketCenterFilters
          selectedPipeline={selectedPipeline}
          setSelectedPipeline={setSelectedPipeline}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          selectedPriority={selectedPriority}
          setSelectedPriority={setSelectedPriority}
          selectedCreatedDate={selectedCreatedDate}
          setSelectedCreatedDate={setSelectedCreatedDate}
          selectedLastActivityDate={selectedLastActivityDate}
          setSelectedLastActivityDate={setSelectedLastActivityDate}
          app={app}
          selectedOption={selectedDate}
          setSelectedOption={setSelectTime}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          setFilterDateType={setFilterDateType}
          filterDateType={filterDateType}
        />
        <BoardContainer>
          <TicketCenterSearchBar setQueryParam={setQueryParam} />
          <div className="kanban-board">
            {ticketBoardDataLoading ? (
              <Skeleton active></Skeleton>
            ) : (
              <Board
                data={mappedData}
                draggable
                // collapsibleLanes
                laneButtonStyle={{ display: 'none' }}
                // onCardDelete={(
                //   cardId,
                //   sourceLaneId,
                //   targetLaneId,
                //   position,
                //   cardDetails
                // ) =>
                //   console.log(`this item is deleted ${cardId} ${sourceLaneId} `)
                // }

                handleDragEnd={(
                  cardId,
                  sourceLaneId,
                  targetLaneId,
                  position,
                  cardDetails
                ) =>
                  handleSubmit(
                    cardId,
                    sourceLaneId,
                    targetLaneId,
                    position,
                    cardDetails
                  )
                }
                cardStyle={cardStyle}
                onCardClick={handleCardClick}
                hideCardDeleteIcon={true}
              />
            )}
          </div>
        </BoardContainer>
      </MainConatiner>
    </Fragment>
  )
}

export default TicketCenter

// src/features/counterSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPage: '',
  sidebarCollapsed: true,
  mailBoxLocationId: '',
  userFlowSelectedTab: '1',
  companyFlowSelectedTab: '1',
  userMailBoxDetails: '',
  userSelectedLocation: '',
  userSelcetedPipeLine: { name: 'Onboarding', id: 1 },
}

export const appSlice = createSlice({
  name: 'AppAuth',
  initialState,
  reducers: {
    handleCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    handleCollapsed: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed
    },
    handMailBoxLocationId: (state, action) => {
      state.mailBoxLocationId = action.payload
    },
    handSelectUserFlowTab: (state, action) => {
      state.userFlowSelectedTab = action.payload
    },
    handSelectCompanyFlowTab: (state, action) => {
      state.companyFlowSelectedTab = action.payload
    },
    handleAddUserMailBoxDetails: (state, action) => {
      state.userMailBoxDetails = action.payload
    },
    handleAddUserelectedLocation: (state, action) => {
      state.userSelectedLocation = action.payload
    },
    handleUserSelcetedPipeLine: (state, action) => {
      state.userSelcetedPipeLine = action.payload
    },
  },
})

export const {
  handleCurrentPage,
  handleCollapsed,
  handMailBoxLocationId,
  handSelectUserFlowTab,
  handSelectCompanyFlowTab,
  handleAddUserMailBoxDetails,
  handleAddUserelectedLocation,
  handleUserSelcetedPipeLine,
} = appSlice.actions

export default appSlice.reducer

import styled from "styled-components";

const ChatHeaderContainer = styled.div`
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #e3eced;
  padding: 5px 15px 5px 15px;
  ${
    "" /* position: absolute;
  top: 0;
  left: 0; */
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: flex;
    padding: 6px 12px;
    ${"" /* max-height: 10px; */}
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
    margin-top: 7px;
  }

  .wrapper {
    margin-right: 20px;
    
  }
  .title {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.63px;
    padding-left: 12px;
  }
  .title1 {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.63px;
  }
  .avatar-name-container {
    display: flex;
    gap: 10px;
    height: 30px;
  }
`;

export default ChatHeaderContainer;

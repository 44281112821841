import React, { useState, useEffect } from "react";
import { FirstColumnContainer } from "./styles";
import GetLogo from "../../getlogo/getlogo";
import ColumnHeader from "./columnHeaderr/ColumnHeader";
import useGetData from "../../../hooks/useGetData";
import { Skeleton } from "antd";

const FirstColumn = ({
  selectedFolder,
  status,
  onFolderSelect,
  isAllClosedSelected,
  setRefetchCount,
  openComposer,
  selectedEmailId,
}) => {
  const [menuItems, setMenuItems] = useState([]);
  const { data: departments, error } = useGetData("support/departments");
  const { data: count, refetchData: refetchCount } = useGetData(
    "support/emails/count"
  );

  const [department, setDepartment] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (departments && departments.data.length > 0) {
      setDepartment(departments.data[0]);
    }
  }, [departments]);

  useEffect(() => {
    if (setRefetchCount) {
      setRefetchCount(refetchCount);
    }
  }, [refetchCount, setRefetchCount]);

  useEffect(() => {
    if (count) {
      setMenuItems([
        {
          key: "1",
          icon: GetLogo("mailbox"),
          label: "Inbox",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.inbox,
        },
        {
          key: "1",
          icon: GetLogo("mailbox"),
          label: "Sent",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.inbox,
        },
        // {
        //   key: "1",
        //   icon: GetLogo("mailbox"),
        //   label: "Sent",
        //   className: "menu-item",
        //   isBadge: true,
        //   count: count?.data?.trash,
        // },
        {
          key: "2",
          icon: GetLogo("reports"),
          label: "Spam",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.spam,
        },
        {
          key: "3",
          icon: GetLogo("delete"),
          label: "Trash",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.trash,
        },
        {
          key: "4",
          icon: GetLogo("mailbox"),
          label: "All closed",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.closed,
        },
      ]);

      setIsInitialLoad(false);
    }
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchCount();
    }, 30000);

    return () => clearInterval(interval);
  }, [refetchCount]);

  return (
    <FirstColumnContainer>
      <Skeleton
        loading={isInitialLoad && (!departments || !count)}
        active
        style={{ margin: "10px", width: "auto" }}
      >
        <div>
          <ColumnHeader
            department={department}
            setDepartment={setDepartment}
            departments={departments?.data}
          />
          {menuItems.map((item) => (
            <div
              className={`sidebar-item ${
                (selectedFolder === item.label && !isAllClosedSelected) ||
                (item.label === "All closed" && isAllClosedSelected)
                  ? "active"
                  : ""
              }`}
              key={item.key}
              onClick={() => onFolderSelect(item.label)}
            >
              <div className="right">
                <span className="icon">{item.icon}</span>
                <span className="label">{item.label}</span>
              </div>
              <span className="left">{item.count}</span>
            </div>
          ))}
        </div>
        {selectedEmailId && (
          <button onClick={openComposer} className="compose-button">
            Compose
          </button>
        )}
      </Skeleton>
    </FirstColumnContainer>
  );
};

export default FirstColumn;

import styled from "styled-components";

const FirstColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-right: 1px solid #e3eced;
  pag: 20px;


  ${'' /* .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    .header-title {
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
    }

    .btn-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .ant-space-item {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        text-align: right;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  } */}

  .company-name {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    width: 100%;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .opened-time {
    color: var(--Gray-70, rgba(85, 99, 115, 0.7));
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const IconButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 0 0;
  border-bottom: 1px solid #e3eced;
  padding: 0 12px 30px 12px;
  gap: 10px;
  
`;

const IconLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  background-color: #f5f8f9;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
`;

const IconLabel = styled.span`
  margin-top: 8px;
  color: var(--Gray-100, #556373);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.42px;
`;

export {
  FirstColumnContainer,
  IconLabelContainer,
  IconLabel,
  IconButtonGroup,
  IconBackground,
};

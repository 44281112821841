import React, { useEffect, useRef, useState } from "react";
import ChatContainer from "./styles";
import ChatMessage from "../chatMessage/ChatMessage";
import moment from "moment";
import { Skeleton } from "antd";

function Chat({ email, currentEmployeeImage }) {
  const chatContainerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(chatContainer.scrollTimeout);
      chatContainer.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [email]);

  if (!email || !email?.data || !email?.data?.threads) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: "10px", width: "auto" }}
      ></Skeleton>
    );
  }

  const { threads, activities } = email?.data;

  // Combine activities and threads
  const combineThreadsAndActivities = () => {
    const combined = [];

    Object.keys(threads).forEach((date) => {
      threads[date].forEach((thread) => {
        combined.push({
          type: "message",
          content: thread,
          timestamp: thread.created_at,
        });
      });
    });

    activities.forEach((activity) => {
      combined.push({
        type: "activity",
        content: activity,
        timestamp: activity.created_at,
      });
    });

    return combined.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  };

  const combinedData = combineThreadsAndActivities();

  const formatTime = (time) => {
    return moment(time).format("ddd, MMM D, YYYY");
  };

  const formatTime1 = (time) => {
    return moment(time).format("h:mm A");
  };

  const formatActivityMessage = (activity) => {
    const formattedTime = moment(activity.created_at).format(
      "MMM D, YYYY h:mm A"
    );
    return `${activity.activity} on ${formattedTime}`;
  };

  return (
    <ChatContainer
      ref={chatContainerRef}
      className={isScrolling ? "scrolling" : ""}
    >
      {combinedData.map((item, index) => (
        <div key={index}>
          {item.type === "message" ? (
            <ChatMessage
              key={item.content.id}
              emailData={email?.data}
              message={item.content.email_body}
              sender={item.content.sender}
              timestamp={formatTime(item.timestamp)}
              time={formatTime1(item.timestamp)}
              imageName={"user"}
              currentEmployeeImage={currentEmployeeImage}
              attachments={item.content.attachments}
              sender_avatar={item.content.sender_avatar}
            />
          ) : (
            <div className="activity">
              <span>{formatActivityMessage(item.content)}</span>
            </div>
          )}
        </div>
      ))}
    </ChatContainer>
  );
}

export default Chat;

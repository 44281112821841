import styled from 'styled-components'

const FilterWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  ${'' /* border-bottom: 1px solid #e3eced; */}
  /* background: #fafbfc; */
  height: 68px;
  background: ${(props) => (props.isCompnayMailBox ? ` #fafbfc` : 'white')};
  .main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
    height: 36px;

    .search-input-div {
      .search-input {
        display: flex;
        width: 208px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 20px;
        background: var(--Soft-Blue-100, #eff5f6);
        height: 32px;
        .input {
          background: var(--Soft-Blue-100, #eff5f6);
          overflow: hidden;
          border: none;
          outline: none;
        }
        .input::placeholder {
          color: var(--Gray-60, rgba(85, 99, 115, 0.6));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.42px;
        }
        .icon {
          width: 16px;
          height: 16px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      .search-icon {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        cursor: pointer;
        /* width: 24px;
      height: 24px; */
        .fa-search {
          display: flex;
          padding: 6px;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;
          box-shadow: 0px 2px 8px 0px rgba(208, 222, 225, 0.6);
        }
        .search-title {
          color: var(--Gray-100, #556373);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
    .search-filters {
      display: flex;
      /* padding: 10px 12px; */
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      gap: 8px;
      .search-button {
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        border-radius: 4px;
        background: var(--Blue-100, #4cbbcb);
        color: var(--White-100, #fff);
        text-align: center;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        border: none;
        cursor: pointer;
        height: 35px;
      }
      .date-search {
        max-width: 124px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        .location {
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;
          max-width: 122px;
          width: 100%;
        }
        input::placeholder {
          color: var(--Gray-100, #556373);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
      .event-search {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        max-width: 124px;
        width: 100%;
        height: 36px;

        .placeholder {
          color: var(--Gray-100, #556373);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
        .searchInput {
          color: var(--Gray-100, #556373);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          padding: 12px;
          border-radius: 4px;
          border: 1px solid #d0dee1 !important;
          background: #fff;
        }
        .search-input:hover:active {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;
        }
        .location {
          display: flex;
          /* padding: 12px; */
          justify-content: space-between;
          /* padding: 12px; */
          border: none;
          outline: none;
          align-items: center;
          align-self: stretch;
          /* border-radius: 4px; */
          /* border: 1px solid #d0dee1; */
          /* border-radius: 4px 0px 0px 4px; */
          background: #fff;
          height: 41px;
          width: 124px;
          .ant-select-selector {
            border: 1px solid #d0dee1;
            box-shadow: none;
          }

          svg {
            fill: #2b3746;
            /* width: 16px;
            height: 16px; */
          }
          .ant-select-selection-placeholder {
            color: var(--Gray-100, #556373);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }
        }
      }
    }
  }
`

export { FilterWrapper }

import styled from "styled-components";

const StatusAndPiplineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;

  .make-row {
    display: flex;
    gap: 5px;
    align-items: center;

    .custom-dropdown {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      ${'' /* width: 100%; */}
    }

    .dropdown-header {
      cursor: pointer;
      display: flex;
      padding: 0px 2px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      ${'' /* max-width: 230px; */}

      .user-name {
        color: #4cbbcb;
        text-align: left;
        font-family: Outfit;
        font-size: 15px;
        font-weight: 500;
        line-height: 16px;
        flex-grow: 1;
      }
    }

    .dropdown-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      z-index: 9;
      width: 120px;
      padding: 16px;
      position: absolute;
      top: 10%;
      right: 0;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #e3eced;
      background: var(--White-100, #fff);
      box-shadow: 0px 5px 10px 0px rgba(33, 85, 93, 0.06);

      li {
        justify-content: flex-start;
        align-items: center;
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
        width: 100%;

        &.role-item {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 8px 0;
          width: 100%;
          border-bottom: 1px solid #e3eced;
          list-style-type: none;

          .items {
            padding: 0;
          }
        }

        &.dropdown-item {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
          width: 100%; /* Ensures dropdown items are aligned */

          .user-avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .user-initials {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #4cbbcb;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            margin-right: 10px;
          }

          &:hover {
            border-radius: 4px;
            background-color: #dbf1f5;
          }
        }
      }
    }

    :where(
        .css-dev-only-do-not-override-11xg00t
      ).ant-select-single.ant-select-show-arrow
      .ant-select-selection-item,
    :where(
        .css-dev-only-do-not-override-11xg00t
      ).ant-select-single.ant-select-show-arrow
      .ant-select-selection-placeholder {
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      width: 100%;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      color: var(--Blue-100, #4cbbcb);
      cursor: pointer;
    }

    .label {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .pipeline {
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      width: 100%;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      color: var(--Blue-100, #4cbbcb);
      cursor: pointer;
    }
  }
`;

export { StatusAndPiplineContainer };

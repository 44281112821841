import React, { useState, useRef, useEffect } from 'react'
import { MainContainer } from './styles' // Import CSS for styling
import useGetData from '../../../hooks/useGetData'
import { handleUserSelcetedPipeLine } from '../../../redux/app/appSlice'
import { useDispatch } from 'react-redux'
import { Button, message, Space } from 'antd'
const TicketBoardTypeDropDown = ({
  selectedPipeline,
  setSelectedPipeline,
  app,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    data: pipeLineData,
    loading,
    refetchData,
    error,
  } = useGetData('support/tickets/pipelines')

  const [messageApi, contextHolder] = message.useMessage()
  const success = () => {}

  const dropdownRef = useRef(null)
  const dispatch = useDispatch()
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    setIsOpen(!isOpen)
    setSelectedPipeline(option)
    dispatch(handleUserSelcetedPipeLine(option))
    app?.userSelcetedPipeLine?.name != option?.name &&
      messageApi.open({
        type: 'success',
        content: `Pipeline has been set to ${option?.name}`,
      })
  }

  return (
    <MainContainer>
      {contextHolder}
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {app?.userSelcetedPipeLine
              ? app?.userSelcetedPipeLine?.name
              : 'Select PipeLine'}
          </div>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#4CBBCB"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#556373"
                stroke-opacity="0.2"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            {pipeLineData?.data?.map((pipeLine) => (
              <li onClick={() => handleOptionSelect(pipeLine)}>
                {pipeLine?.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </MainContainer>
  )
}

export default TicketBoardTypeDropDown

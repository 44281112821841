import React, { useState } from "react";
import { Tabs } from "antd";
import { MainWrapper } from "./styles";
import MissedCallsTable from "../../../liveAnswering/missedCallsTable/MissedCallsTable";
import VoiceMailsTable from "../../../liveAnswering/voiceMailsTable/VoiceMailsTable";
import LiveAnswerSettings from "../liveAnswerSettings/LiveAnswerSettings";
import MailboxSettings from "../mailboxSettings/mailboxSettings";
import PhoneNumberSettings from "../phoneNumberSettings/phoneNumberSettings";

function ProductSettingTabs({ activeTab, setActiveTab, setSettingType }) {
  const handleChange = (key) => {
    setActiveTab(key);
    if (key == 1) {
      setSettingType("Mailbox");
    }
    if (key == 2) {
      setSettingType("Live Answering");
    }
    if (key == 3) {
      setSettingType("Phone Number");
    }
    if (key == 4) {
      setSettingType("Company Registeration");
    }
    if (key == 5) {
      setSettingType("Office Access");
    }
  };
  const { TabPane } = Tabs;
  return (
    <MainWrapper>
      <Tabs
        activeKey={activeTab}
        onChange={handleChange}
        tabBarStyle={{ borderBottom: "none" }}
        animated={false}
      >
        <TabPane
          tab={<span className="custom-tab-label">Mailbox</span>}
          key="1"
        >
          <MailboxSettings />
        </TabPane>
        <TabPane
          tab={<span className="custom-tab-label">Live Answering</span>}
          key="2"
        >
          <LiveAnswerSettings></LiveAnswerSettings>
        </TabPane>
        <TabPane
          tab={<span className="custom-tab-label">Phone Number</span>}
          key="3"
        >
          <PhoneNumberSettings />
        </TabPane>
        <TabPane
          tab={<span className="custom-tab-label">Company Registeration</span>}
          key="4"
        ></TabPane>
        <TabPane
          tab={<span className="custom-tab-label">Office Access</span>}
          key="5"
        ></TabPane>
      </Tabs>
    </MainWrapper>
  );
}

export default ProductSettingTabs;

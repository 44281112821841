import styled from "styled-components";

const MainWrapper = styled.div`
  border-bottom: none !important;
  .ant-tabs-nav {
    border-bottom: none !important;
    margin: 0;
    padding-left: 16px;
    width: -webkit-fill-available;
    background-color: #fafbfc;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    /* background-color: #fafbfc; */
  }

  .ant-tabs-content-holder {
    /* margin-top: 92px; */
  }
  .custom-tab-label {
    display: flex;
    width: 126px;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-bottom: none !important;
    border-bottom: 1px solid #e3eced;
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
  }
  .ant-tabs-tab {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab-active {
    display: flex;
    width: 126px;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid #e3eced;
    border-right: 1px solid #e3eced;
    border-left: 1px solid #e3eced;
    background: #fff;
    .custom-tab-label {
      color: #4cbaca;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.42px;
    }
  }
`;

export { MainWrapper };

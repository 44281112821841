import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  ${"" /* background: red; */}

  .first-column,
  .second-column,
  .third-column {
    height: 100vh;
    overflow-y: scroll;
    padding-top: 40px;

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .first-column {
    flex: 2.5;
  }

  .second-column {
    flex: 5;
    background: #f5f8f9;
    border-right: 1px solid #e3eced;
  }

  .third-column {
    flex: 2.5;
    padding: 50px 15px 0px 15px;
    min-width: 370px;
  }
`;

const BtnWarapper = styled.div`
  width: 100%;

  .save-btn {
    display: flex;
    width: 100%;
    /* width: 339px; */
    padding: 16px;
    justify-content: start;
    gap: 15px;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 8px;
    background-color: var(--Blue-100, #fbfafb);
    border: 1px solid #ececec;

    .btn {
      padding: 12px;
      gap: 10px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #4cbbcb;
      border: none;
      text-align: center;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      max-width: 72px;
      width: 100%;
    }
    .cancel-btn {
      background-color: transparent;
      border: 1px solid #bbc0c7;
    }
    .save-btn {
      background-color: #4bbbcb;
    }
    .save-btn-disabled {
      background-color: transparent;
      border: 1px solid #bbc0c7;
    }
  }
`;

export { MainContainer, BtnWarapper };

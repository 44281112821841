import React from "react";
import AvatarContainer from "./styles";
import user1 from "../../../../pages/support/user1.jpg";

const Avatar = ({ sender_avatar, sender }) => {
  return (
    <AvatarContainer>
      <div className="avatarContainer">
        {sender_avatar ? (
          <img className="image-style" src={sender_avatar} />
        ) : (
          <img className="image-style" src={user1} alt="Avatar" />
        )}
      </div>
    </AvatarContainer>
  );
};

export default Avatar;

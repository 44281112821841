import React, { useEffect, useRef, useState } from "react";
import {
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
} from "./styles";
import { FiPaperclip } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import useGetData from "../../../../../hooks/useGetData";
Quill.register("modules/emoji", Emoji);

const CommentBody = ({
  onFileSelect,
  onInputChange,
  handleSend,
  savingComment,
  editingNote,
  value,
  setValue,
}) => {
  const fileInputRef = useRef(null);
  const quillRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const { data: employees } = useGetData("users-home");

  useEffect(() => {
    setValue(editingNote?.notes || "");
  }, [editingNote, setValue]);

  useEffect(() => {
    if (employees && employees.roles) {
      const allUsers = employees.roles.flatMap((role) => role.users);
      setEmployeesList(allUsers);
    }
  }, [employees]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    onFileSelect(event.target.files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    onFileSelect(updatedFiles);
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setValue(content);
    onInputChange("comment", content);
    const cursorPos = editor.getSelection()?.index;

    if (cursorPos !== null) {
      const textBeforeCursor = content.slice(0, cursorPos);

      // Immediately show dropdown when '@' is typed
      if (textBeforeCursor.endsWith("@")) {
        setCursorPosition(cursorPos);
        setDropdownVisible(true);
      } else {
        setDropdownVisible(false);
      }
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleEmployeeSelect = (employee) => {
    const quill = quillRef.current.getEditor();

    if (quill && cursorPosition !== null) {
      const textBeforeCursor = quill.getText(0, cursorPosition);
      const match = textBeforeCursor.match(/@(\w*)$/);

      if (match) {
        const searchText = match[1];
        const startPos = cursorPosition - searchText.length - 1;
        const textToInsert = `@${employee.first_name} ${employee.last_name}`;

        quill.deleteText(startPos, searchText.length + 1); // Delete the '@' and the partial search text
        quill.insertText(startPos, textToInsert, { bold: true }); // Insert the full name in bold

        // Clear formatting so subsequent text is not bold
        quill.formatText(startPos + textToInsert.length, 0, { bold: false });

        // Move cursor to the end of the inserted text
        quill.setSelection(startPos + textToInsert.length);

        // Update the value in the state and hide the dropdown
        setValue(quill.root.innerHTML);
        setDropdownVisible(false);

        // Reset cursor position after employee selection
        setCursorPosition(null);
      }
    }
  };

  return (
    <MessageBodyContainer>
      <div className="text-area-wrapper">
        <ReactQuill
          ref={quillRef}
          name="message"
          className="quill-editor"
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          placeholder="Type your message here..."
          modules={{
            toolbar: {
              container: "#quill-toolbar",
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true,
            },
          }}
        />
      </div>
      {dropdownVisible && employeesList.length > 0 && (
        <div className="employee-dropdown">
          {employeesList.map((employee) => (
            <div
              key={employee.id}
              className="employee-item"
              onClick={() => handleEmployeeSelect(employee)}
            >
              {employee.first_name} {employee.last_name}
            </div>
          ))}
        </div>
      )}

      <AttachmentList>
        {selectedFiles.map((file, index) => (
          <AttachmentItem key={index}>
            <TruncateText text={file.name}></TruncateText>
            <span className="file-size">
              ({(file.size / 1024).toFixed(1)}kb)
            </span>
            <RemoveIcon onClick={() => handleRemoveFile(index)}>
              <IoClose />
            </RemoveIcon>
          </AttachmentItem>
        ))}
      </AttachmentList>

      <ButtonsWrapper>
        <div id="quill-toolbar" className="custom-toolbar">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-link"></button>
        </div>
        <Button onClick={handleFileClick}>
          <FiPaperclip />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
        </Button>

        <Button onClick={handleSend} className="send-button">
          {savingComment ? "Saving" : "Save"}
        </Button>
      </ButtonsWrapper>
    </MessageBodyContainer>
  );
};

export default CommentBody;

const TruncateText = ({ text }) => {
  const maxLength = 20;
  let truncatedText = text.substring(0, maxLength);
  if (truncatedText.length < text.length) {
    truncatedText += "...";
  }
  return <span title={text}>{truncatedText}</span>;
};

import React, { useEffect, useRef, useState } from 'react'
import { MailboxContainer, EnableNotification } from './styles'
import { Button, Form, Input, Switch } from 'antd'

const CompanyMailbox = ({
  form,
  companyData,
  handleFormItemChange,
  currentDashBoardLofigEmial,
}) => {
  const [enableAddEmail, setEnableAddEmail] = useState(false)
  const dashboardEmailInputRef = useRef(null)

  const onFinish = (values) => {
    console.log('Received values:', values)
  }

  const onFinishFailed = (values) => {
    console.log(values, 'values with failed')
  }

  const handleSwitchChange = (checked) => {
    form.setFieldsValue({ mailboxNotificationCheck: checked })
    setEnableAddEmail(checked)
  }

  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        mailboxNotificationCheck:
          companyData?.data?.mailbox_email_notifications &&
          companyData?.data?.mailbox_email_notifications !== ''
            ? JSON.parse(companyData?.data?.mailbox_email_notifications)
            : false,
        emailForMailBoxNotification:
          companyData?.data?.email_for_mailbox_email_notifications,
      })
      companyData?.data?.mailbox_email_notifications &&
        setEnableAddEmail(
          JSON.parse(companyData?.data?.mailbox_email_notifications)
        )
    }
  }, [companyData, form])

  const SetDashboardLoginEmail = () => {
    if (
      dashboardEmailInputRef &&
      currentDashBoardLofigEmial?.current?.input?.value
    ) {
      form.setFieldsValue({
        emailForMailBoxNotification:
          currentDashBoardLofigEmial.current.input.value ||
          companyData?.data?.dashboard_login_email,
      })
    }
  }

  return (
    <MailboxContainer>
      <Form
        requiredMark={false}
        className="form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleFormItemChange}
      >
        <div className="title">Mailbox</div>
        <EnableNotification>
          <span className="label1">Email Notifications</span>
          <Form.Item
            name="mailboxNotificationCheck"
            valuePropName="checked"
            style={{ marginTop: '25px' }}
          >
            <Switch className="switch" onChange={handleSwitchChange} />
          </Form.Item>
        </EnableNotification>

        <div
          className="dashboard-email-main"
          style={{ visibility: enableAddEmail ? 'visible' : 'hidden' }}
        >
          <Form.Item
            label="Email for mail notifications"
            name="emailForMailBoxNotification"
            className="input-label"
          >
            <Input
              className="dashboard-email-input"
              placeholder="gonzales@gmail.com"
              disabled={!enableAddEmail}
              ref={dashboardEmailInputRef}
            />
          </Form.Item>
          <div
            className="dashboard-email-btns"
            onClick={SetDashboardLoginEmail}
          >
            <Button className="btn">Use Dashboard Email</Button>
          </div>
        </div>
      </Form>
    </MailboxContainer>
  )
}

export default CompanyMailbox

import styled from "styled-components";

const SecondColumnContainer = styled.div`
  border-right: 1px solid #e3eced;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 10px 15px;
    border-bottom: 1px solid #e3eced;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .selected-mails {
      color: #4cbbcb;
      text-align: right;
      font-family: Outfit;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      flex-grow: 1;
    }

    .btn-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      margin-top: 2px;
      .ant-space-item {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        text-align: right;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */

        span {
          svg {
            /* width: 16px;
          height: 16px; */
          }
        }
      }
    }
  }

  .email-list {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .email-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #e3eced;
    box-sizing: border-box;

    &:hover {
      background-color: rgba(239, 245, 246, 0.5);
    }
  }

  .selected {
    position: relative;
    background-color: rgba(239, 245, 246, 0.5);
    color: var(--Blue-100, #4cbbcb);
  }

  .selected::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: var(--Blue-100, #4cbbcb);
  }

  .email-header {
    display: flex;
    align-items: flex-start;
    .email-checkbox {
      color: #fff;
      padding: 6px;
      border-radius: 25px;
      font-size: 14px;
      width: 30px;
      height: 30px;
      text-align: center;
      juctify-content: center;
    }
  }

  .email-icon {
    background-color: #2b3746;
    color: #fff;
    padding: 6px;
    border-radius: 25px;
    font-size: 14px;
    width: 30px;
    height: 30px;
    text-align: center;
  }

  .selected-email-icon {
    background-color: var(--Blue-100, #4cbbcb);
    color: #fff;
    padding: 6px;
    border-radius: 25px;
    font-size: 14px;
    width: 30px;
    height: 30px;
    text-align: center;
  }

  .email-details {
    flex: 1;
    margin-left: 10px;
  }

  .email-top-row {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    .email-sender {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .unread-email-sender {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .email-time {
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .unread-email-time {
      color: black;
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .email-subject {
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--Gray-100, #556373);
  }

  .unread-email-subject {
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: black;
  }

  .email-preview {
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--Gray-100, #556373);
  }
`;

const StyledCheckboxContainer = styled.label`
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const HiddenCheckboxInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

// Styled custom checkbox
const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 4px;
  border: ${(props) =>
    props.checked ? "2px solid #4CBBCB" : "2px solid #D8DBE3"};
  background-color: ${(props) =>
    props.checked ? "rgba(76, 187, 203, 0.70)" : "transparent"};
  position: relative;

  &:after {
    content: "\u2713";
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${(props) => (props.checked ? "block" : "none")};
  }
`;

const StyledCheckboxText = styled.span`
  color: var(--Dark-Gray-100, #2b3746);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export {
  SecondColumnContainer,
  StyledCheckboxContainer,
  HiddenCheckboxInput,
  StyledCheckbox,
  StyledCheckboxText,
};

import styled from "styled-components";

const AllTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* border-bottom: 1px solid #e3eced; */}
  border-top: 1px solid #e3eced;
`;

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  font-size: 14px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.49px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? "#4cbbcb" : "#ffffff")};
  color: ${(props) =>
    props.isActive ? "#ffffff" : "var(--Dark-Gray-100, #2b3746)"};

  &:hover {
    background-color: ${(props) => (props.isActive ? "#4cbbcb" : "#f0f0f0")};
  }
`;

const TabContent = styled.div`
  padding: 10px;
  font-size: 14px;
`;

const ArrowIcon = styled.span`
  margin-right: 10px;
  margin-top: 5px;
  font-size: 16px;
  color: ${(props) => (props.isActive ? "#ffffff" : "#4cbbcb")};
`;

export { AllTabsContainer, TabContainer, TabHeader, TabContent, ArrowIcon };

import React from "react";
import { FaUserCircle } from "react-icons/fa";
import AvaterContainer from "./styles";
import user1 from "../../../../../pages/support/user1.jpg";
import user from "../../../../../pages/support/user.jpg";

const Avatar = ({ currentEmployeeImage }) => {
  const firstNameInitial = currentEmployeeImage?.first_name?.[0] || "";
  const lastNameInitial = currentEmployeeImage?.last_name?.[0] || "";

  return (
    <AvaterContainer>
      <div className="avaterContainer">
        {currentEmployeeImage?.avatar ? (
          <img
            src={currentEmployeeImage?.avatar}
            alt={`${currentEmployeeImage?.first_name || ""} ${
              currentEmployeeImage?.last_name || ""
            }`}
            className="image"
          />
        ) : (
          <>
            {firstNameInitial || lastNameInitial ? (
              <div className="user-initials">
                {firstNameInitial}
                {lastNameInitial}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </AvaterContainer>
  );
};

export default Avatar;

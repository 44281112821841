import React, { useState, useEffect } from "react";
import { FaEnvelope, FaRegTrashAlt, FaRegCommentDots } from "react-icons/fa";
import {
  SecondColumnContainer,
  HiddenCheckboxInput,
  StyledCheckbox,
  StyledCheckboxContainer,
} from "./styles";
import { Dropdown, Menu, Skeleton, Space } from "antd";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import useGetData from "../../../hooks/useGetData";
import DataNotFound from "../../../common/DataNotFound";

const SecondColumn = ({
  onEmailSelect,
  selectedEmailId,
  selectedFolder,
  status,
  setRefetchEmails,
  isInitialLoad,
  setIsInitialLoad,
  selectedEmails,
  setSelectedEmails,
}) => {
  const [hoveredEmailId, setHoveredEmailId] = useState(null);

  const [order, setOrder] = useState("DESC");
  const [isFolderChange, setIsFolderChange] = useState(false);

  const {
    data: emails,
    error: emailError,
    loading,
    refetchData: refetchEmails,
  } = useGetData(
    `support/emails/${selectedFolder.toLowerCase()}?status=${status}&order_by=created_at&order=${order}`
  );

  useEffect(() => {
    if (setRefetchEmails) {
      setRefetchEmails(refetchEmails);
    }
  }, [refetchEmails, setRefetchEmails]);

  useEffect(() => {
    setIsFolderChange(true);
    refetchEmails();
    setIsFolderChange(false);
  }, [selectedFolder]);

  useEffect(() => {
    if (isInitialLoad) {
      if (emails?.data?.length > 0) {
        onEmailSelect(emails?.data[0]?.id);
        setIsInitialLoad(false);
      }
    }
  }, [emails]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchEmails();
    }, 30000);

    return () => clearInterval(interval);
  }, [refetchEmails]);

  const getIcon = (type) => {
    switch (type) {
      case "email":
        return <FaEnvelope />;
      case "trash":
        return <FaRegTrashAlt />;
      case "chat":
        return <FaRegCommentDots />;
      default:
        return <FaEnvelope />;
    }
  };

  const handleCheckboxChange = (emailId) => {
    setSelectedEmails((prevSelected) =>
      prevSelected.includes(emailId)
        ? prevSelected.filter((id) => id !== emailId)
        : [...prevSelected, emailId]
    );
  };

  const handleSelectAllChange = () => {
    console.log("selectedEmails", selectedEmails);
    setSelectedEmails((prevSelected) =>
      prevSelected.length === emails?.data?.length
        ? []
        : emails?.data?.map((email) => email.id)
    );
  };

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const formatTime = (time) => {
    const now = moment();
    const updatedAt = moment(time);

    if (now.diff(updatedAt, "minutes") < 60) {
      return `${now.diff(updatedAt, "minutes")}m`;
    } else if (now.diff(updatedAt, "hours") < 24) {
      return `${now.diff(updatedAt, "hours")}h`;
    } else if (now.diff(updatedAt, "days") === 1) {
      return "Yesterday";
    } else {
      return updatedAt.format("MMM D");
    }
  };

  const handleSortOrderChange = (newOrder) => {
    setOrder(newOrder);
  };

  const sortingMenu = (
    <Menu>
      <Menu.Item onClick={() => handleSortOrderChange("DESC")}>
        Newest
      </Menu.Item>
      <Menu.Item onClick={() => handleSortOrderChange("ASC")}>Oldest</Menu.Item>
    </Menu>
  );

  return (
    <SecondColumnContainer>
      <Skeleton
        loading={isInitialLoad || isFolderChange}
        active
        style={{ margin: "10px", width: "auto" }}
      >
        <div className="header">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: "7px",
            }}
          >
            <StyledCheckboxContainer>
              <HiddenCheckboxInput
                checked={selectedEmails.length === emails?.data?.length}
                onChange={handleSelectAllChange}
              />
              <StyledCheckbox
                checked={selectedEmails.length === emails?.data?.length}
                onChange={handleSelectAllChange}
              />
            </StyledCheckboxContainer>
            {selectedEmails?.length > 0 && (
              <span className="selected-mails">{selectedEmails?.length + " " + "Selected"}</span>
            )}
          </div>
          {selectedEmails?.length <= 0 && (
            <Dropdown overlay={sortingMenu} trigger={["click"]}>
              <Space className="btn-title">
                {order === "DESC" ? "Newest" : "Oldest"}
                <DownOutlined />
              </Space>
            </Dropdown>
          )}
        </div>
        <div className="email-list">
          {emails?.data?.length > 0 ? (
            emails.data.map((email) => (
              <div
                key={email.id}
                className={`email-item ${
                  email.id === selectedEmailId ? "selected" : ""
                }`}
                onClick={() => onEmailSelect(email.id)}
                onMouseEnter={() => setHoveredEmailId(email.id)}
                onMouseLeave={() => setHoveredEmailId(null)}
              >
                <div className="email-header">
                  {hoveredEmailId === email.id ||
                  selectedEmails.includes(email.id) ? (
                    <div className="email-checkbox">
                      <StyledCheckboxContainer>
                        <HiddenCheckboxInput
                          checked={selectedEmails.includes(email.id)}
                          onChange={() => handleCheckboxChange(email.id)}
                        />
                        <StyledCheckbox
                          checked={selectedEmails.includes(email.id)}
                          onChange={() => handleCheckboxChange(email.id)}
                        />
                      </StyledCheckboxContainer>
                    </div>
                  ) : (
                    <div
                      className={`email-icon ${
                        email.id === selectedEmailId
                          ? "selected-email-icon"
                          : ""
                      }`}
                    >
                      {getIcon(email.type)}
                    </div>
                  )}
                  <div className="email-details">
                    <div className="email-top-row">
                      <span
                        className={
                          email.read_status
                            ? "email-sender"
                            : "unread-email-sender"
                        }
                      >
                        {truncateText(email.sender_name, 15)}
                      </span>
                      <span
                        className={
                          email.read_status ? "email-time" : "unread-email-time"
                        }
                      >
                        {formatTime(email.created_at)}
                      </span>
                    </div>
                    <div
                      className={
                        email.read_status
                          ? "email-subject"
                          : "unread-email-subject"
                      }
                    >
                      {truncateText(email.subject, 15)}
                    </div>
                    <div className="email-preview">
                      {truncateText(
                        email.email_body.replace(/<\/?[^>]+(>|$)/g, ""),
                        27
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-emails">
              {emails?.data && <DataNotFound label={"No email Found"} />}
            </div>
          )}
        </div>
      </Skeleton>
    </SecondColumnContainer>
  );
};

export default SecondColumn;

import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import ChatHeaderContainer from "./styles";
import ChatHeaderDropDown from "../chatHeaderDropDown/ChatHeaderDropDown";
import Avatar from "./avatar/avatar";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { Popconfirm } from "antd";
import { IoMdContract } from "react-icons/io";

const ChatHeader = ({
  selectedEmailId,
  setCurrentEmployeeImage,
  currentEmployeeImage,
  email,
}) => {
  const addEmailIdToLocalStorage = (emailId) => {
    const savedEmailIds =
      JSON.parse(localStorage.getItem("emailIdsArray")) || [];
    if (!savedEmailIds.includes(emailId)) {
      savedEmailIds.push(emailId);
      localStorage.setItem("emailIdsArray", JSON.stringify(savedEmailIds));
    }
  };

  return (
    <ChatHeaderContainer>
      <div className="wrapper">
        <span className="title">Owner</span>
        <div className="avatar-name-container">
          <Avatar currentEmployeeImage={currentEmployeeImage} />
          <ChatHeaderDropDown
            selectedEmailId={selectedEmailId}
            setCurrentEmployeeImage={setCurrentEmployeeImage}
            email={email}
          ></ChatHeaderDropDown>
        </div>
      </div>
      {/* <button onClick={() => addEmailIdToLocalStorage(selectedEmailId)}>
        <IoMdContract size={18} />
      </button> */}
    </ChatHeaderContainer>
  );
};

export default ChatHeader;

import React, { useEffect, useState, useRef } from "react";
import { FilterWrapper } from "./styles";
import { Select, DatePicker } from "antd";

import useGetData from "../../../hooks/useGetData";
import GetLogo from "../../getlogo/getlogo";
import CustomDropdown from "../../../common/customDropdown/CustomDropdown";

function SearchFilters({
  setEventName,
  setSearchparma,
  setStartDate,
  setEndtDate,
  isCompnayMailBox,
  setEventType,
  setSearchParam,
  handleSearchInput,
}) {
  const { data, loading, error } = useGetData("get-virtual-mailbox-events");

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [startDateToDisabele, setStartDateToDisabele] = useState(null);
  const [selectedEvent, setSelectEvent] = useState();

  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (inputRef.current.value.trim() === "") {
          setIsSearchVisible(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleStartDateChange = (date, dateString) => {
    if (date === null) {
      setStartDate(null);
    } else {
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      console.log(formattedDate, "formattedDate sart");
      setStartDateToDisabele(date);
      setStartDate(formattedDate);
    }
  };
  const handleEndDateChange = (date, dateString) => {
    if (date === null) {
      setEndtDate(null);
    } else {
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      console.log(formattedDate);
      setEndtDate(formattedDate);
    }
  };

  const disabledEndDate = (current) => {
    return (
      current &&
      startDateToDisabele &&
      current.valueOf() < startDateToDisabele.valueOf()
    );
  };
  return (
    <FilterWrapper isCompnayMailBox={isCompnayMailBox}>
      <div className="main-wrapper">
        <div className="search-input-div">
          {isSearchVisible ? (
            <div className="search-input">
              <span className="icon">{GetLogo("searchIcon")}</span>
              <input
                // onChange={(e) => handleSearchInput(e.target.value)}
                type="text"
                className="input"
                placeholder="Search |"
                ref={inputRef}
              />
            </div>
          ) : (
            <div className="search-icon">
              <i className="fas fa-search" onClick={handleSearchIconClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <g clip-path="url(#clip0_3290_44862)">
                    <path
                      d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5Z"
                      stroke="#2B3746"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.4996 10.4996L8.34961 8.34961"
                      stroke="#2B3746"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3290_44862">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              <div className="search-title">Search call here</div>
            </div>
          )}
        </div>
        <div className="search-filters">
          <div className="event-search">
            <CustomDropdown
              options={data?.data}
              onSelect={setEventType}
            ></CustomDropdown>
          </div>

          <div className="event-search date-search">
            <DatePicker
              placeholder="Start Date"
              className="location"
              onChange={handleStartDateChange}
            ></DatePicker>
          </div>
          <div className="event-search date-search">
            <DatePicker
              placeholder="End Date"
              className="location"
              onChange={handleEndDateChange}
              disabledDate={disabledEndDate}
            ></DatePicker>
          </div>
          <div className="event-search">
            <button
              className="search-button"
              // onClick={() => handleSearchInput("")}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
}

export default SearchFilters;

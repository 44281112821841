import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  height: 52px;
  padding: 12px 24px;
  justify-content: space-between;
  align-self: stretch;
  background: #fcfdfd;
  border: 1px solid #ebf1f2;
  border-left: none;
  margin-top: 42px;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 2;
  border-top: none;

  .title {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    /* width: 100%; */
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }
  .filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* width: 100%; */
    gap: 8px;
    .datePicker {
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      gap: 5.333px;
      border-radius: 4px 4px 0px 4px;
      border: 1px solid #e3eced;
      background: transparent;
      .ant-picker-clear {
        display: none;
      }
      .ant-picker-input > input::placeholder {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .btn-title {
      display: flex;
      padding: 5px 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 28px;
      border-radius: 4px 4px 0px 4px;
      border: 1px solid #e3eced;
      .ant-space-item {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        text-align: right;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */

        span {
          svg {
            /* width: 16px;
          height: 16px; */
          }
        }
      }
    }
  }
  .add-btn {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    border: none;
    cursor: pointer;
    color: var(--White-100, #fff);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }
`

export { Container }

import React, { useEffect, useState } from "react";
import { Form, Modal, Select, Button } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner } from "./styles";
import TextArea from "antd/lib/input/TextArea";
import { InstanceWithAuth } from "../../../../../../App";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const { Option } = Select;

const CraeteTicket = ({
  isPipelineModelOpen,
  handleCancel,
  handleOk,
  pipelines,
  selectedPipeline,
  stages,
  onPipelineChange,
  selectedEmailId,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth } = useSelector((state) => state);

  const handleSave = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setIsSaving(true); // Disable the button and change the title to "Saving..."
        try {
          const response = await InstanceWithAuth.post(
            `support/tickets/email-to-ticket/${selectedEmailId}`,
            {
              support_pipeline_id: values.pipeline,
              support_pipeline_stage_id: values.stage,
              description: values.description,
              priority: values.priority,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${auth?.userObj?.api_token}`,
              },
            }
          );

          if (response?.status === 201) {
            form.resetFields();
            handleOk();
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: response?.data?.message,
              customClass: {
                confirmButton: "custom-swal-button",
              },
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error?.response?.data?.message,
            customClass: {
              confirmButton: "custom-swal-button",
            },
          });
        } finally {
          setIsSaving(false); // Re-enable the button and reset the title
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (selectedPipeline) {
      form.setFieldsValue({ pipeline: selectedPipeline.id });
    }
  }, [selectedPipeline]);

  return (
    <Modal
      closeIcon={<RxCross2 />}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isPipelineModelOpen}
      onCancel={handleCancel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Form className="main-form" form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="title-main">
              <span className="title">Create Ticket</span>
            </div>

            <div className="form-container">
              <div className="form-main">
                <div className="form">
                  <Form.Item
                    name="pipeline"
                    label="Pipeline"
                    className="form-items"
                    rules={[
                      { required: true, message: "Please select a pipeline!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a pipeline"
                      onChange={onPipelineChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {pipelines?.map((pipeline) => (
                        <Option key={pipeline.id} value={pipeline.id}>
                          {pipeline.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="stage"
                    label="Stage"
                    rules={[
                      { required: true, message: "Please select a stage!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a stage"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {stages.map((stage) => (
                        <Option key={stage.id} value={stage.id}>
                          {stage.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="priority"
                    label="Priority"
                    rules={[
                      { required: true, message: "Please select a priority!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Set priority"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value="Low">Low</Option>
                      <Option value="Medium">Medium</Option>
                      <Option value="High">High</Option>
                      <Option value="Very High">Very High</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="welcome-message">
                <Form.Item
                  label="Ticket Description"
                  name="description"
                  className="form-items"
                  rules={[
                    { required: true, message: "Please enter a description!" },
                  ]}
                >
                  <TextArea
                    className="input"
                    placeholder="Ticket description here..."
                  />
                </Form.Item>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
              disabled={isSaving}
            >
              Close
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={isSaving}
              className={isSaving ? "saving" : ""}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </Modal>
  );
};

export default CraeteTicket;

import React from 'react'
import { SubHeaderConatiner, BannerContainer } from './styles'
import GetLogo from '../../getlogo/getlogo'
import NameAvatar from '../../../common/NameAvatar'
import CustomTooltip from '../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip'
import { Skeleton } from 'antd'
export default function UserFlowSubHeader({ contactData, contactDataLoading }) {
  const redirectToUrl = (dashboard_login_email) => {
    const baseUrl = 'https://clone.starthubcenters.com'
    const email = decodeURIComponent(dashboard_login_email)
    const params = `autologin=true&email=${email}`
    window.open(`${baseUrl}?${params}`, '_blank')
  }

  return (
    <BannerContainer>
      <div className="main-wrapper">
        <div className="user-detail">
          <div className="user">
            {contactDataLoading ? (
              <Skeleton.Avatar active size={36} shape="circle" />
            ) : contactData?.data?.avatar ? (
              <img
                src={contactData?.data?.avatar}
                alt=""
                className="user-img"
              />
            ) : (
              <NameAvatar
                userName={`${contactData?.data?.first_name || ''} ${
                  contactData?.data?.last_name || ''
                }`}
                val={18}
                fontSize={11}
              />
            )}
            <p className="user-name">
              {contactDataLoading ? (
                <Skeleton.Input active size={23} block="false" />
              ) : (
                `${contactData?.data?.first_name || ''} ${
                  contactData?.data?.last_name || ''
                }`
              )}
            </p>
          </div>
          <div className="btns">
            {contactData && (
              <>
                <button
                  className={
                    contactData?.data?.user?.online
                      ? 'manager-btn'
                      : 'manager-btn'
                  }
                >
                  <div
                    className={
                      contactData?.data?.status == 0 ? 'in-active' : 'active'
                    }
                  >
                    <span className="btn-icon">{GetLogo('circle')}</span>
                    <span className="text">
                      {contactData?.data?.status == 0 ? 'Inactive' : 'Active'}
                    </span>
                  </div>
                </button>
                <CustomTooltip text={'Impersonate client'}>
                  <button
                    onClick={() =>
                      redirectToUrl(contactData?.data?.dashboard_login_email)
                    }
                    className="impression-btn"
                  >
                    <span className="btn-icon">{GetLogo('eye')}</span>
                  </button>
                </CustomTooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </BannerContainer>
  )
}

import React from "react";
import "./styles";
import { Container, Title, UserAvatar } from "./styles";
import { useNavigate } from "react-router-dom";
import useGetData from "../../../../../hooks/useGetData";
import { useDispatch } from "react-redux";
import NameAvatar from "../../../../../common/NameAvatar";
import { LuExternalLink } from "react-icons/lu";
import { Skeleton } from "antd";

const SupportHeader = ({ userID, contactData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    navigate(`/companie/${userID}`);
  };

  return (
    <Container>
      <Title>
        <div className="avatar-wrapper">
          <div className="user-img">
            {contactData?.data?.avatar ? (
              <img src={contactData?.data?.avatar} alt="" />
            ) : (
              <NameAvatar
                val={40}
                userName={`${contactData?.data?.name || "User"}`}
              ></NameAvatar>
            )}
          </div>

          <div className="name-wrapper">
            <span className="name">{contactData?.data?.name}</span>
            <span className="desc">{contactData?.data?.address}</span>
          </div>
        </div>
        <div className="icons">
          <span className="icon" onClick={() => handleRedirect("1")}>
            <LuExternalLink size={17}></LuExternalLink>
          </span>
        </div>
      </Title>
    </Container>
  );
};

export default SupportHeader;

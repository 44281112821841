import React, { useState } from "react";
import { PageContainer, TabsContainer } from "./styles";
import CommonHaeder from "../../../components/header/commonHeader/CommonHaeder";
import ProductSettingsSubHeader from "../../../components/settings/productSettings/productSettingsSubHeader/LiveAnsweringSubHeader";
import GeneralSettingsTabs from "../../../components/settings/generalSettings/generalSettingsTabs/GeneralSettingsTabs";

const GeneralSettings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [settingType, setSettingType] = useState("Chargify");
  const [eventType, setEventType] = useState("");

  return (
    <>
      <CommonHaeder
        firstBcItem={"Settings"}
        firstBcIcon={"setting"}
        secondBcItem={"General Settings"}
        secondBcIcon="setting"
      ></CommonHaeder>
      <ProductSettingsSubHeader title="General Settings"></ProductSettingsSubHeader>
      <TabsContainer>
        <GeneralSettingsTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setSettingType={setSettingType}
          setEventType={setEventType}
        ></GeneralSettingsTabs>
      </TabsContainer>
    </>
  );
};

export default GeneralSettings;

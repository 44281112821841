import styled from "styled-components";

const Table = styled.table`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  /* background: #fafbfc; */
  .heading-row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    align-self: stretch;
    border-radius: 4px;
    background: #f3f6f7;
    border: 1px solid #d0dee1;
    padding: 0px 32px 0px 18px;

    .title {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 177.778% */
      text-transform: uppercase;
    }

    .checkbox {
      max-width: 40px;
      width: 100%;
      height: 40px;
      /* justify-content: center; */
      /* padding: 16px 12px; */
      display: flex;

      padding: 12px 18px 12px 0px;
      /* padding: 12px; */
      border-radius: 4px 0px 0px 4px;
      align-items: center;
      border-right: 1px solid #d0dee1;

      .custom-input input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        /* width: 16px;
        height: 16px; */
      }

      .custom-input label {
        position: relative;
        cursor: pointer;
        /* width: 8px;
        height: 8px; */
      }

      .custom-input label:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #4cbbcb;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 7px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
        border-radius: 4px;
        /* width: 4px;
        height: 4px; */
      }

      .custom-input input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 5.5px;
        left: 5.2px;
        width: 5px;
        height: 8px;
        border: solid #4cbbcb;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    .name {
      display: flex;
      max-width: 155px;
      width: 100%;
      height: 100% !important;
      padding: 20px;
      align-items: center;
      border-right: 1px solid #d0dee1;
    }

    .caller-name {
      display: flex;
      max-width: 158px;
      width: 100%;
      padding: 12px;
      align-items: center;
      flex: 1 0 0;
      ${"" /* border-right: 1px solid #d0dee1; */}
    }

    .phone {
      display: flex;
      max-width: 141.5px;
      width: 100%;
      padding: 12px;
      align-items: center;
      flex: 1 0 0;
      border-right: 1px solid #d0dee1;
    }

    .date {
      display: flex;
      max-width: 141.4px;
      width: 100%;
      padding: 12px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-right: 1px solid #d0dee1;
    }

    .time {
      display: flex;
      width: 100%;
      max-width: 141.5px;
      padding: 12px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-right: 1px solid #d0dee1;
    }

    .reason {
      display: flex;
      max-width: 141.5px;
      width: 100%;
      padding: 12px;
      align-items: center;
      gap: 10px;
      border-right: 1px solid #d0dee1;
    }
    .status {
      display: flex;
      max-width: 139px;
      width: 100%;
      padding: 12px;
      align-items: center;
      gap: 10px;
      border-right: 1px solid #d0dee1;
    }
    .actions {
      display: flex;
      max-width: 235px;
      width: 100%;
      padding: 12px;
      align-items: center;
      gap: 10px;
      /* border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05)); */
    }
  }

  .active-cls {
    border-radius: 8px;
    outline: 1px solid var(--Blue-100, #4cbbcb);
    background: var(--Blue-20, rgba(76, 187, 203, 0.2));
    box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
  }

  .td-heading-border {
    border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
  }

  .td-active-border {
    border-right: 1px solid var(--Blue-30, rgba(76, 187, 203, 0.3)) !important;
  }

  .td-heading {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid #e3eced;
    /* background: #fafbfc; */
    /* display: flex; */
    padding: 8px 18px 8px 18px;
    /* align-items: center; */
    /* align-self: stretch; */

    .td-title {
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }

    .td-checkbox {
      display: flex;
      max-width: 40px;
      width: 100%;
      padding: 16px 18px 16px 0px;
      align-items: flex-start;
      gap: 10px;
      .custom-input input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        /* width: 16px;
        height: 16px; */
      }

      .custom-input label {
        position: relative;
        cursor: pointer;
        /* width: 8px;
        height: 8px; */
      }

      .custom-input label:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #4cbbcb;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 7px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
        border-radius: 4px;
        /* width: 4px;
        height: 4px; */
      }

      .custom-input input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 3.5px;
        left: 5.2px;
        width: 5px;
        height: 8px;
        border: solid #4cbbcb;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    .td-id {
      /* border-right: 1px solid white; */

      display: flex;
      width: 100%;
      max-width: 155px;
      padding: 16px 12px;
      /* justify-content: center; */
      align-items: center;
      gap: 10px;
      height: 48px;
    }

    .td-mailbox-id {
      display: flex;
      max-width: 158px;
      width: 158px;
      padding: 16px 12px;
      align-items: center;
      height: 48px;
      gap: 10px; /* 100% */
      font-weight: 700;
      color: black;
    }

    .td-sender {
      display: flex;
      padding: 16px 12px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      max-width: 141.5px;
      width: 100%;
      height: 48px;
    }

    .td-send-date {
      display: flex;
      padding: 16px 12px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      max-width: 141.5px;
      width: 100%;
      height: 48px;
    }
    .td-type {
      display: flex;
      padding: 16px 12px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      max-width: 141.5px;
      width: 100%;
      height: 48px;
    }
    .td-QTY {
      display: flex;
      padding: 16px 12px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      max-width: 141.5px;
      width: 100%;
      height: 48px;
    }

    .td-status {
      display: flex;
      padding: 16px 12px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      max-width: 139px;
      height: 48px;
      width: 100%;
      span {
        display: flex;
        padding: 6px 12px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
        background: rgba(76, 203, 96, 0.2);
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        height: 26px;
      }
    }
    .td-action {
      display: flex;
      max-width: 290px;
      padding: 16px 0px;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 10px;
      height: 48px;

      .icons-wrapper {
        display: flex;
        max-width: 288px;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
        ${"" /* background: red; */}
        gap: 30px;

        span:hover {
          cursor: pointer;
          svg path {
            fill: #2b3746;
          }
        }
      }
    }
  }
  .hovered {
    background-color: #dbf1f5;
    border-radius: 8px;
    border-radius: 8px;
    border: 1px solid var(--Blue-100, #4cbbcb);
    background: var(--Blue-20, rgba(76, 187, 203, 0.2));
    box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
    span {
      cursor: pointer;
      svg path {
        fill: #2696a6 !important;
      }
    }
  }
  .add-btn {
    display: flex;
    max-width: 1229px;
    height: 56px;
    padding: 12px;
    align-items: center;
    gap: 10px;
    border-radius: 0px 0px 8px 8px;
    /* border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05)); */
    /* background: var(--Gray-2, rgba(85, 99, 115, 0.02)); */
    background: transparent;
    border: none;

    .btn-text {
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
    }

    .btn-icon {
      width: 16px;
      height: 16px;
      svg {
        width: 16px;
        height: 16px;
        fill: #556373;
      }
    }
  }
  .pagination-container {
    display: flex;
    width: 100%;
  }
  .table-footer {
    display: flex;
    height: 56px;
    padding: 12px 32px;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    width: 100%;
    position: relative;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    background: var(--Gray-2, rgba(85, 99, 115, 0.02));
  }
`;
const PaginationContainer = styled.div`
  .ant-pagination-prev {
    min-width: 16px !important;
    width: 16px !important;
  }
  .ant-pagination-next {
    min-width: 16px !important;
    width: 16px !important;
  }
  .ant-pagination-item {
    min-width: 24px !important;
    height: 16px !important;
    width: 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
  }
  .pagination-div {
    .ant-pagination-options {
      display: none;
    }
    .ant-pagination-prev {
      width: 16px !important;
      height: 16px !important;
      width: 16px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      svg {
        margin-bottom: 9px;
      }
    }

    .ant-pagination-next {
      width: 16px !important;
      height: 16px !important;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      svg {
        margin-bottom: 9px;
      }
    }

    :where(.css-dev-only-do-not-override-2rgkd4)
      .ant-pagination
      .ant-pagination-prev {
      min-width: 16px !important;
      width: 16px !important;
    }
    .ant-pagination-item {
      width: 24px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      a {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
        text-transform: uppercase;
      }
    }
    .ant-pagination-item-active {
      width: 24px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: rgba(76, 187, 203, 0.15);
      /* border: 1px solid #4cbbcb; */
      border: none;
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 160% */
      text-transform: uppercase;
      a {
        color: var(--Blue-100, #4cbbcb);
        font-family: Outfit;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
        text-transform: uppercase;
      }
    }
  }
`;

export { Table, PaginationContainer };

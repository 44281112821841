import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
const Spinner = ({ width, color = 'white' }) => (
  <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: width,
          color: color,
        }}
        spin
      />
    }
  />
)
export default Spinner
